import React, { useEffect, useState, useMemo } from 'react'
import { RootState } from 'reducers'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { magiContants, validationMessageSet } from 'utils/contants'
import { miscRegexSet } from 'utils/regex'
import { Formik, FormikProps, Form } from 'formik'
import { Dialog, DialogActions } from '@material-ui/core'
import { ErrorFocus } from 'sp/componentsHsc'
import Loading from 'sp/componentsHsc/Loading'
import {
  changeConfirmState,
  closeConfirmModal,
  close060ConfirmModal,
  confirm,
  getInit,
  inputAttachment,
  saveDraft,
  setConfirmRequest,
  setDraftReqeust,
  setRecruitmentManagementDivision,
  setIsDraft,
  inputSubject,
  inputBody,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputNotificationMailAddress,
  inputNotificationSelect,
  inputInterviewDetail,
  getPreview,
  getNotAdoptedNotification,
  clearCandidate,
  inputManipulationSelection,
  changeTransmission,
  inputSendTimer
} from 'reducers/messageSendReducer'
import SendTarget from './SendTarget'
import ScheduleAdjustment from './ScheduleAdjustment'
import MessageInput from './MessageInput'
import SendTime from './SendTime'
import Reminder from './Reminder'
import { MCAZS080MessageDraftRequest } from 'types/MCAZS080/MCAZS080MessageDraftRequest'
import { MCAZS080MessageConfirmRequest } from 'types/MCAZS080/MCAZS080MessageConfirmRequest'
import {
  InputValues,
  messageInitRequest,
  targetTextAreas,
  textMap,
  notAdoptedNotificationRequest,
} from 'pages/MCAZS080/formConfig'
import MCAZS080Validation from 'validations/MCAZS080/MCAZS080MessageConfirmRequestValidation'
import MCAZM091 from 'sp/pages/MCAZM091'
import MCAZM130 from 'sp/pages/MCAZM130'
import { isEmpty, stringToDateTime } from 'common/generalUtil'
import { onKeyDown } from 'common/generalUtil'
import * as yup from 'yup'
import { LoadingDialog } from 'sp/components'
import { MCAZS080MessagePreviewRequest } from 'types/MCAZS080/MCAZS080MessagePreviewRequest'
// edge05 74716 start
import MessageTemplate from './MessageTemplate'
// edge05 74716 end

import * as viewConfig from 'pages/MCAZS080/viewConig'
import * as viewConfig091 from 'pages/MCAZS091/viewConig'
import { Reservation } from './Calendar'

const localStorageName = 'messageInputRequest'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    backgroundColor: '#fff',
    boxShadow:
      ' 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    padding: '20px 12px 0',
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  root: {
    display: 'block',
    marginTop: '2px',
    marginLeft: '2px',
    marginRight: '2px',
    padding: '5px',
  },
  section: {
    minWidth: '0 !important',
    '& div.ta-unset': {
      textAlign: 'unset',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      marginBottom: '20px',
      '& button': {
        width: 'auto',
        position: 'absolute',
        left: '0',
        top: '-48px',
        margin: '0',
      },
      '& .btn.high': {
        left: 'auto',
      },
    },
  },
  loading: {
    marginTop: '200px',
    textAlign: 'center',
    fontSize: '2.1rem',
    height: '100%',
    position: 'absolute',
    zIndex: 15,
    top: 0,
    width: '100%',
  },
}))

let initialValues: InputValues = {
  templateName: { label: '', value: '' },
  sendTarget: 'onlyMynavi',
  sendTime: null,
  sendTimeTransmission: '',
  reminder: '',
  reminderSelect: magiContants.NOTIFICATION_DESIRED,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  interviewDetail: '',
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  barcodeDisplay: '',
  repliesLimit: null,
  sendTimeType: '',
}

type Props = {
  onDialogClose?: Function
  isCompletedSending: boolean
  registerValue: viewConfig.RegisterValue
  setConfState: (value: viewConfig091.MessageConfVal) => void
  setMCAZS101Open: (value: boolean) => void
  mCAZS080Open: boolean
  minimization: boolean
  setMinimization: (value: boolean) => void
}

const MCAZM080 = ({
  onDialogClose,
  isCompletedSending,
  registerValue,
  setConfState,
  setMCAZS101Open,
  mCAZS080Open,
  minimization,
  setMinimization,
}: Props) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialog060Open, setConfirmDialog060Open] = useState(false)
  const [
    confirmDialogDraftCandidateOpen,
    setConfirmDialogDraftCandidateOpen,
  ] = useState(false)
  const [type, setType] = useState<keyof typeof textMap>('confirm')
  const dispatch = useDispatch()
  const classes = useStyles()
  const [attachmentFileValue, setAttachmentFileValue] = useState<any[]>([])
  const snackbar = useSelector((state: RootState) => state.snackbar)
  const globalMenuInfo = useSelector((state: RootState) => state.globalMenu)
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const targetList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.sendTarget
  )

  const entryBoxList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.messageInput.entryBox
  )
  const seminarList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.messageInput.seminar
  )
  const destinationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .destinationSelection
  )
  const hasCloseWindow = useSelector(
    (state: RootState) => state.messageSend.hasCloseWindow
  )
  const hasCloseWindowByDraft = useSelector(
    (state: RootState) => state.messageSend.hasCloseWindowByDraft
  )
  const messageInput = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.messageInput
  )
  const sendTimeInput = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.sendTimeInput
  )
  const notification = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.notification
  )
  const interviewSchedule = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.interviewSchedule
  )
  const runConfirm = useSelector(
    (state: RootState) => state.messageSend.runConfirm
  )
  const openConfirm = useSelector(
    (state: RootState) => state.messageSend.openConfirm
  )
  const open060ConfirmModal = useSelector(
    (state: RootState) => state.messageSend.open006ConfirmModal
  )
  const manipulationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .manipulationSelection
  )
  const isDraft = useSelector((state: RootState) => state.messageSend.isDraft)
  const isInitialized = useSelector(
    (state: RootState) => state.messageSend.isInitialized
  )
  const selectedTemplateSettingId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.title.selectedTemplateSettingId
  )
  const forbiddenWords = useSelector(
    (state: RootState) => state.messageSend.initResult.forbiddenWord
  )
  const attachmentFileRegistrationMax = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.attachmentFileRegistrationMax
  )
  const mynaviAttachedOptionMax = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.mynaviAttachedOptionRegistrationMax
  )
  const readableFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.readableFlag
  )
  const [mCAZS091Open, setMCAZS091Open] = useState(false)
  const [isOnlyAnonymous, setIsOnlyAnonymous] = useState(false)
  const [containsOtherSelection, setContainsOtherSelection] = useState(false)
  const [
    initialValueSenderCompanyName,
    setInitialValueSenderCompanyName,
  ] = useState('')
  const [
    initialValueSenderMailAddress,
    setInitialValueSenderMailAddress,
  ] = useState('')
  const [initialValueReminder, setInitialValueReminder] = useState('')
  const [initialValueReminderSelect, setInitialValueReminderSelect] = useState(
    magiContants.NOTIFICATION_DESIRED
  )
  const [initialValueSubject, setInitialValueSubject] = useState('')
  const [initialValueBody, setInitialValueBody] = useState('')
  const [
    initialValueInterviewDetail,
    setInitialValueInterviewDetail,
  ] = useState('')
  const [initialValueHomepageTitle1, setInitialValueHomepageTitle1] = useState(
    ''
  )
  const [initialValueHomepageTitle2, setInitialValueHomepageTitle2] = useState(
    ''
  )
  const [initialValueHomepageTitle3, setInitialValueHomepageTitle3] = useState(
    ''
  )
  const [initialValueHomepageUrl1, setInitialValueHomepageUrl1] = useState('')
  const [initialValueHomepageUrl2, setInitialValueHomepageUrl2] = useState('')
  const [initialValueHomepageUrl3, setInitialValueHomepageUrl3] = useState('')
  const [initialValueRepliesLimit, setInitialValueRepliesLimit] = useState('')
  const [initialValueSendTimeType, setInitialValueSendTimeType] = useState('')
  const [mCAZS130Open, setMCAZS130Open] = useState(false)
  const [
    selectedInterviewLocationId,
    setSelectedInterviewLocationId,
  ] = useState('0')
  const [candidateListId, setCandidateListId] = useState(0)
  const [selectInterviewLocationId, setSelectInterviewLocationId] = useState(
    '0'
  )
  const [
    selectInterviewLocationName,
    setSelectInterviewLocationName,
  ] = useState('')
  const [
    selectInterviewLocationAddress,
    setSelectInterviewLocationAddress,
  ] = useState('')
  const [
    selectInterviewLocationMapUrl,
    setSelectInterviewLocationMapUrl,
  ] = useState('')
  const [isFixedSubject, setIsFixedSubject] = useState(false)
  const previewDataList = useSelector(
    (state: RootState) => state.messageSend.previewDataList
  )
  const isNotAdoptedNotification = useSelector(
    (state: RootState) => state.messageSend.isNotAdoptedNotification
  )
  const interviewWebUrlSendFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewWebUrlSendFlag
  )

  const [current, setCurrent] = useState<FormHandler>()

  const [timeState, setTimeState] = useState<boolean>(false)

  const [isReplyMessage, setIsReplyMessage] = useState<boolean>(false)

  const reminderYup = yup.object().shape({
    reminder: yup
      .string()
      .notRequired()
      .test('isEmails', validationMessageSet.email(), (value: string) => {
        if (value) {
          const isValid = value
            .split(',')
            .map(email => email.trim())
            .filter(v => !isEmpty(v))
            .find(
              v =>
                !yup
                  .string()
                  .test('isMail', validationMessageSet.email, (value: any) =>
                    miscRegexSet.email.test(value)
                  )
                  .isValidSync(v)
            )
          return !isValid
        } else {
          return true
        }
      })
      .max(160, validationMessageSet.maxLength),
  })

  const addAttachmentFileNameYup = yup.object().shape({
    addAttachmentFileName: yup.string(),
  })

  const staffNameYup = yup.object().shape({
    staffName: yup.string(),
  })

  const subjectYup = yup.object().shape({
    subject: yup
      .string()
      .test('required', validationMessageSet.required, (value: string) => {
        if (isEmpty(value)) {
          return false
        }
        return !isEmpty(value.trim())
      })
      .max(200, validationMessageSet.maxLength)
      .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
        miscRegexSet.halfWidth.test(value)
      ),
  })

  const bodyYup = yup.object().shape({
    body: yup
      .string()
      .test('required', validationMessageSet.required, (value: string) => {
        if (isEmpty(value)) {
          return false
        }
        return !isEmpty(value.trim())
      })
      .max(
        destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
          ? 2000
          : 1959,
        validationMessageSet.maxLength
      )
      .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
        miscRegexSet.halfWidth.test(value)
      ),
  })

  const repliesLimitYup = yup.object().shape({
    repliesLimit: yup
      .string()
      .test('isDate', validationMessageSet.date, (value: any) =>
        miscRegexSet.date.test(value)
      ),
  })

  initialValues = {
    templateName: { label: '', value: '' },
    sendTarget: 'onlyMynavi',
    sendTime: null,
    sendTimeTransmission: '',
    reminder: initialValueReminder,
    reminderSelect: initialValueReminderSelect,
    senderCompanyName: initialValueSenderCompanyName,
    senderMailAddress: initialValueSenderMailAddress,
    subject: initialValueSubject,
    body: initialValueBody,
    interviewDetail: initialValueInterviewDetail,
    homepageTitle1: initialValueHomepageTitle1,
    homepageTitle2: initialValueHomepageTitle2,
    homepageTitle3: initialValueHomepageTitle3,
    homepageUrl1: initialValueHomepageUrl1,
    homepageUrl2: initialValueHomepageUrl2,
    homepageUrl3: initialValueHomepageUrl3,
    barcodeDisplay: '',
    repliesLimit: initialValueRepliesLimit,
    sendTimeType: initialValueSendTimeType,
  }

  useEffect(() => {
    if(destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY){
      dispatch(inputManipulationSelection(magiContants.SELECTION_NUMBER_NOT_PRESENT))
      dispatch(clearCandidate())
    }
  }, [destinationSelection])

  useEffect(() => {
    if (isInitialized) {
      setInitialValueReminder(
        notification.notificationMailAddress
          ? notification.notificationMailAddress
          : ''
      )
      setInitialValueReminderSelect(notification.notificationSelect)
      setInitialValueSenderCompanyName(
        messageInput.senderCompanyName ? messageInput.senderCompanyName : ''
      )
      setInitialValueSenderMailAddress(
        messageInput.senderMailAddress ? messageInput.senderMailAddress : ''
      )
      setInitialValueSubject(messageInput.subject ? messageInput.subject : '')
      setInitialValueBody(messageInput.body ? messageInput.body : '')
      setInitialValueInterviewDetail(
        interviewSchedule.interviewDetail
          ? interviewSchedule.interviewDetail
          : ''
      )
      setInitialValueHomepageTitle1(
        messageInput.homePage[0] ? messageInput.homePage[0].homePageTitle : ''
      )
      setInitialValueHomepageTitle2(
        messageInput.homePage[1] ? messageInput.homePage[1].homePageTitle : ''
      )
      setInitialValueHomepageTitle3(
        messageInput.homePage[2] ? messageInput.homePage[2].homePageTitle : ''
      )
      setInitialValueHomepageUrl1(
        messageInput.homePage[0] ? messageInput.homePage[0].homePageUrl : ''
      )
      setInitialValueHomepageUrl2(
        messageInput.homePage[1] ? messageInput.homePage[1].homePageUrl : ''
      )
      setInitialValueHomepageUrl3(
        messageInput.homePage[2] ? messageInput.homePage[2].homePageUrl : ''
      )
      setInitialValueRepliesLimit(
        messageInput.repliesLimit ? messageInput.repliesLimit : ''
      )
      setInitialValueSendTimeType(
        sendTimeInput.transmission ? sendTimeInput.transmission : ''
      )
    }
  }, [isInitialized, selectedTemplateSettingId])

  useEffect(() => {
    if (
      !isCompletedSending &&
      snackbar.message === '' &&
      snackbar.type === ''
    ) {
      if (hasCloseWindow) {
        if (onDialogClose) {
          onDialogClose()
        }
      }
      if (
        recruitmentManagementDivision ===
          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 ||
        !isNotAdoptedNotification
      ) {
        dispatch(changeConfirmState(false))
        dispatch(closeConfirmModal())
        setMCAZS091Open(false)
      }
    }
  }, [snackbar])

  useEffect(() => {
    dispatch(setRecruitmentManagementDivision(recruitmentManagementDivision))
    const messageInputRequest = localStorage.getItem(localStorageName)
    if (messageInputRequest) {
      const newParams = JSON.parse(messageInputRequest)
      if (newParams.notAdoptedNotificationType) {
        dispatch(
          getNotAdoptedNotification({
            ...notAdoptedNotificationRequest,
            ...newParams,
          })
        )
      } else {
        dispatch(getInit({ ...messageInitRequest, ...newParams }))
      }
      if (newParams.replyMessageSendId) {
        setIsReplyMessage(true)
      }
      localStorage.removeItem(localStorageName)
    } else {
      dispatch(getInit(messageInitRequest))
    }
  }, [dispatch])

  useEffect(() => {
    if (runConfirm) {
      const formData: MCAZS080MessageConfirmRequest = confirmRequest
      const files: any[] = attachmentFileValue
      const entryTypeList: string[] = targetList.map(item => item.entryType)
      const viewEndTimeList = targetList.map(item => item.viewEndTime)
      dispatch(
        confirm({
          formData,
          files,
          entryTypeList,
          viewEndTimeList,
          forbiddenWords,
          entryBoxList,
          seminarList,
          attachmentFileRegistrationMax,
          mynaviAttachedOptionMax,
          sendToNonMemberCheck,
          readableFlag,
        })
      )
    }
  }, [runConfirm])

  useEffect(() => {
    if (openConfirm) {
      if (snackbar.message === '' && snackbar.type === '') {
        setMCAZS091Open(true)
      }
    } else {
      setMCAZS091Open(false)
    }
  }, [openConfirm])

  useEffect(() => {
    if (mCAZS091Open === false) {
      dispatch(changeConfirmState(false))
      dispatch(closeConfirmModal())
    }
  }, [mCAZS091Open])

  useEffect(() => {
    if (open060ConfirmModal) {
      setConfirmDialog060Open(true)
    }
  }, [open060ConfirmModal])

  useEffect(() => {
    if (isNotAdoptedNotification) {
      const request: MCAZS080MessagePreviewRequest = {
        companyId: '',
        entryId: targetList.map(item => item.entryId),
        interviewScheduleAdjustmentId: null,
        interviewScheduleId: null,
      }
      dispatch(getPreview({ formData: request }))
      dispatch(setConfirmRequest())
    }
  }, [isNotAdoptedNotification])

  const confirmRequest = useSelector(
    (state: RootState) => state.messageSend.confirmRequest
  )

  const sendToNonMemberCheck = useSelector(
    (state: RootState) => state.messageSend.registerValue.sendToNonMember
  )
  const draftRequest = useSelector(
    (state: RootState) => state.messageSend.draftRequest
  )

  const changeFile = (event: any) => {
    const index: number = Number(event.target.id.substring('file'.length))
    const file: any = document.getElementById(event.target.id)
    const fileName: string = event.target.value.substring(
      event.target.value.lastIndexOf('\\') + 1
    )
    if (!isEmpty(fileName)) {
      dispatch(inputAttachment({ index, fileName }))
      attachmentFileValue[index] = file.files[0]
      setAttachmentFileValue(attachmentFileValue)
    }
  }

  const deleteFile = (index: number) => {
    let newFileList = attachmentFileValue
    newFileList.splice(index, 1)
    setAttachmentFileValue(newFileList)
  }

  const clearFile = () => {
    const emptyList: any[] = []
    setAttachmentFileValue(emptyList)
  }

  const handleOnClose = () => {
    setMCAZS091Open(false)
    if (!isNotAdoptedNotification) {
      setTimeout(() => {
        let anchorElement = document.getElementById('msgTop')
        if (anchorElement) {
          anchorElement.scrollTop = 0
        }
      }, 100)
    } else if (onDialogClose) {
      onDialogClose()
    }
  }

  const setTime = (index: any) => {
    let anchorElement = document.getElementById('id_' + index)
    if (anchorElement) {
      anchorElement.scrollIntoView()
    }
  }

  const setAttachment = (index: any) => {
    let anchorElement = document.getElementById('f' + index)
    if (anchorElement) {
      anchorElement.scrollIntoView()
    }
  }

  const candidateDate = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.candidateDate
  )

  // 「確認する」ボタン押下
  const handleSubmit = (values: InputValues) => {
    let minutesFlag = true
    let attachmentFlag = true
    let indexVal: any[] = []
    candidateDate.forEach((item, index) => {
      const interviewStartTime =
        item.interviewStartTime == null || item.interviewStartTime == ''
          ? null
          : stringToDateTime(item.interviewStartTime)
      const interviewLocationId = item.interviewLocationId
      if (
        (!isEmpty(interviewLocationId) && !interviewStartTime) ||
        (interviewStartTime &&
          (interviewStartTime.getHours() < 9 ||
            interviewStartTime.getHours() > 22)) ||
        (interviewStartTime &&
          interviewStartTime.getMinutes() % magiContants.ZS080_TIMEINTERVALS !==
            0)
      ) {
        indexVal.push(index)
        minutesFlag = false
      } else {
        if (!staffNameYup.isValidSync({ staffName: item.interviewStaffName })) {
          indexVal.push(index)
          minutesFlag = false
        }
      }
    })

    if (minutesFlag) {
      messageInput.attachment.forEach((item, index) => {
        if (
          !addAttachmentFileNameYup.isValidSync({
            addAttachmentFileName: item.attachmentFileName,
          })
        ) {
          indexVal.push(index)
          attachmentFlag = false
        }
      })
    }

    if (minutesFlag && attachmentFlag) {
      dispatch(inputBody({ target: targetTextAreas.body, value: values.body }))
      dispatch(
        inputSubject({ target: targetTextAreas.subject, value: values.subject })
      )
      dispatch(inputSenderCompanyName({ value: values.senderCompanyName }))
      dispatch(inputSenderMailAddress({ value: values.senderMailAddress }))
      dispatch(inputNotificationMailAddress({ value: values.reminder }))
      dispatch(inputNotificationSelect({ value: values.reminderSelect }))
      dispatch(inputInterviewDetail({ value: values.interviewDetail }))
      dispatch(setConfirmRequest())
    } else if (!minutesFlag) {
      setTime(indexVal[0])
    } else {
      setAttachment(indexVal[0])
    }
  }

  // 「キャンセルする」ボタン押下
  const handleClose = () => {
    setType('cancel')
    setConfirmDialogOpen(true)
  }

  // 「下書き保存」ボタン押下
  const handleSaveDraft = (form: FormHandler) => {
    setCurrent(form)
    dispatch(setIsDraft(true))

    form.setTouched({
      subject: true,
      body: true,
      homepageTitle1: true,
      homepageTitle2: true,
      homepageTitle3: true,
      homepageUrl1: true,
      homepageUrl2: true,
      homepageUrl3: true,
      senderCompanyName: true,
      senderMailAddress: true,
      reminder: true,
      interviewDetail: true,
      repliesLimit: true,
    })

    if (manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
      if (
        !form.values.interviewDetail ||
        form.values.interviewDetail === '' ||
        form.errors.interviewDetail
      ) {
        dispatch(setIsDraft(false))
        return false
      }
      // 面接日程 有無チェック
      if (!candidateDate.some(item => !isEmpty(item.interviewLocationId))) {
        setConfirmDialogDraftCandidateOpen(true)
        return false
      }
    }
    if (
      !repliesLimitYup.isValidSync({ repliesLimit: form.values.repliesLimit })
    ) {
      dispatch(setIsDraft(false))
      return false
    }
    let errFlag = false
    candidateDate.forEach(item => {
      const interviewStartTime =
        item.interviewStartTime == null || item.interviewStartTime == ''
          ? null
          : stringToDateTime(item.interviewStartTime)
      const interviewLocationId = item.interviewLocationId
      if (
        (!isEmpty(interviewLocationId) && !interviewStartTime) ||
        (interviewStartTime &&
          (interviewStartTime.getHours() < 9 ||
            interviewStartTime.getHours() > 22)) ||
        (interviewStartTime &&
          interviewStartTime.getMinutes() % magiContants.ZS080_TIMEINTERVALS !==
            0)
      ) {
        // 候補日のチェック
        errFlag = true
      } else if (
        !staffNameYup.isValidSync({ staffName: item.interviewStaffName })
      ) {
        // 担当者のチェック
        errFlag = true
      }
    })
    if (errFlag) {
      dispatch(setIsDraft(false))
      return false
    }
    // 通知先メールアドレスチェック
    if (form.values.reminder !== '') {
      if (reminderYup.isValidSync({ reminder: form.values.reminder })) {
        form.errors.reminder = ''
      }
    }
    if (form.errors.reminder && form.errors.reminder !== '') {
      dispatch(setIsDraft(false))
      return false
    }
    if (form.values.subject !== '') {
      if (subjectYup.isValidSync({ subject: form.values.subject })) {
        form.errors.subject = ''
      }
    }

    if (form.values.body !== '') {
      if (bodyYup.isValidSync({ body: form.values.body })) {
        form.errors.body = ''
      }
    }
    if (
      (!form.errors.subject || form.errors.subject === '') &&
      (!form.errors.body || form.errors.body === '')
    ) {
      setType('draft')
      if (form.values.sendTimeType === '' && !timeState && !errFlag && !form.errors.reminder && form.errors.reminder === '' ) {
        dispatch(changeTransmission({value:'1'}))
        dispatch(inputSendTimer({value:null}))
      }
      dispatch(
        inputBody({ target: targetTextAreas.body, value: form.values.body })
      )
      dispatch(
        inputSubject({
          target: targetTextAreas.subject,
          value: form.values.subject,
        })
      )
      dispatch(inputSenderCompanyName({ value: form.values.senderCompanyName }))
      dispatch(inputSenderMailAddress({ value: form.values.senderMailAddress }))
      dispatch(inputNotificationMailAddress({ value: form.values.reminder }))
      dispatch(inputNotificationSelect({ value: form.values.reminderSelect }))
      dispatch(inputInterviewDetail({ value: form.values.interviewDetail }))
      dispatch(setDraftReqeust())
      setConfirmDialogOpen(true)
    } else {
      dispatch(setIsDraft(false))
    }
  }

  // 下書き完了ダイアログのOKボタン押下
  const handleDraftClose = () => {
    dispatch(setIsDraft(false))
    if (onDialogClose) {
      onDialogClose()
    }
  }

  // 下書き(面接日程0件エラー)のダイアログのOKボタン押下
  const handleCandidateClose = () => {
    dispatch(setIsDraft(false))
    setConfirmDialogDraftCandidateOpen(false)
  }

  const handleConfrim060 = () => {
    setConfirmDialog060Open(false)
    dispatch(changeConfirmState(false))
    dispatch(closeConfirmModal())
    dispatch(close060ConfirmModal())
    setMCAZS091Open(true)
  }

  const handleCancel060 = () => {
    setConfirmDialog060Open(false)
    dispatch(changeConfirmState(false))
    dispatch(closeConfirmModal())
    dispatch(close060ConfirmModal())
  }

  // 「いいえ」ボタン押下
  const handleCancel = () => {
    setConfirmDialogOpen(false)
    dispatch(setIsDraft(false))
    if(current){
      dispatch(changeTransmission({value:current.values.sendTimeType}))
      dispatch(inputSendTimer({value:current.values.sendTime}))
    }
  }

  // 「はい」ボタン押下
  const handleConfirm = () => {
    if (type === 'draft') {
      let formData: MCAZS080MessageDraftRequest = draftRequest
      let files: any[] = attachmentFileValue
      dispatch(
        saveDraft({
          formData,
          files,
          attachmentFileRegistrationMax,
          readableFlag,
        })
      )
      dispatch(setIsDraft(false))
    }
    if (type === 'cancel') {
      if (onDialogClose) {
        onDialogClose()
      }
    }
    setConfirmDialogOpen(false)
  }

  const existsPreviewData = () => {
    return previewDataList && previewDataList.length > 0
  }

  const [inView, setInView] = useState('sendTarget')

  const [toolTipInterviewDetail, setToolTipInterviewDetail] = useState(false)
  const [
    toolTipToolTipCalenderChoose,
    setToolTipToolTipCalenderChoose,
  ] = useState(false)
  const [toolTipReserved,setToolTipReserved,] = useState(false)
  const [currentReservationList,setCurrentReservationList,] = useState<Reservation[]>([])

  const childrenDynamicStyle = useMemo(() => {
    // 最小化時にコンテンツを非表示にする。
    if (minimization) {
      return {
        display: 'none',
      }
    }

    return {}
  }, [minimization])

  return (
    <>
      <>
        {isInitialized ? (
          <>
            <div
              style={{
                display: !(mCAZS091Open && mCAZS080Open && existsPreviewData())
                  ? ''
                  : 'none',
              }}>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={MCAZS080Validation({
                  recruitmentManagementDivision: recruitmentManagementDivision,
                  destinationSelection: destinationSelection,
                  action: manipulationSelection,
                  notificationSelect: notification.notificationSelect,
                  isDraft: isDraft,
                })}
                render={formik => (
                  <Form onKeyDown={onKeyDown}>
                    <div className='modal-content'>
                      <div className='modal-title-wrap'>
                        <h3 className='modal-title'>メッセージ送信</h3>
                        <span
                          className='message-switch-btn'
                          onClick={() => setMinimization(!minimization)}>
                          {minimization ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='31'
                              height='25'
                              viewBox='0 0 62.023 50'
                              className='message-max-btn'
                              style={{ display: 'block' }}>
                              <rect
                                width='48.017'
                                height='35'
                                transform='translate(14.007)'
                                fill='#fff'
                              />
                              <rect
                                width='48.017'
                                height='35'
                                transform='translate(2 13)'
                                fill='#fff'
                                stroke='#133e80'
                                stroke-width='4'
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='57.017'
                              height='11'
                              viewBox='0 0 57.017 11'
                              className='message-min-btn'>
                              <rect
                                id='長方形_995'
                                width='57.017'
                                height='11'
                                fill='#fff'
                              />
                            </svg>
                          )}
                        </span>
                        <span className='close-btn' onClick={handleClose}>
                          <svg
                            width='25'
                            height='25'
                            viewBox='0 0 50 50'
                            fill='#fff'>
                            <use xlinkHref='#form__modal_close'></use>
                          </svg>
                        </span>
                      </div>
                      <div
                        id='msgTop'
                        className={'modal-section__include-anchor'}
                        style={childrenDynamicStyle}>
                        {/* テンプレートを選択 */}
                        {recruitmentManagementDivision ===
                        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 ? (
                          <MessageTemplate
                            form={formik}
                            clearFile={clearFile}
                            isInitialized={isInitialized}
                          />
                        ) : (
                          ''
                        )}
                        {/* 送信先 送信対象 */}
                        <SendTarget
                          isInitialized={isInitialized}
                          setIsOnlyAnonymous={setIsOnlyAnonymous}
                          isOnlyAnonymous={isOnlyAnonymous}
                          setContainsOtherSelection={setContainsOtherSelection}
                          companyName={messageInput.senderCompanyName}
                          mailAddress={messageInput.senderMailAddress}
                          clearFile={clearFile}
                          isReplyMessage={isReplyMessage}
                        />
                        {/* 送信先 送信対象 */}

                        {/* 日程調整ブロック */}
                        {(destinationSelection ===
                          magiContants.DESTINATION_DIVISION_MYNAVI_ONLY &&
                          !containsOtherSelection &&
                          //  [phase2] 日程調整エリア（※就職の場合はトルツメ）
                          recruitmentManagementDivision !=
                            magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1) ||
                        //  [phase2] 日程調整エリア（※就職の場合はトルツメ） end
                        (recruitmentManagementDivision !=
                          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
                          interviewWebUrlSendFlag ===
                            magiContants.INTERVIEW_WEB_URL_SEND_FLAG) ? (
                          <ScheduleAdjustment
                            selectedInterviewLocationId={
                              selectedInterviewLocationId
                            }
                            candidateListId={candidateListId}
                            updateFormModal={mCAZS130Open}
                            selectInterviewLocationId={
                              selectInterviewLocationId
                            }
                            selectInterviewLocationName={
                              selectInterviewLocationName
                            }
                            selectInterviewLocationAddress={
                              selectInterviewLocationAddress
                            }
                            selectInterviewLocationMapUrl={
                              selectInterviewLocationMapUrl
                            }
                            setUpdateFormModal={setMCAZS130Open}
                            setSelectInterviewLocationId={
                              setSelectInterviewLocationId
                            }
                            setSelectInterviewLocationName={
                              setSelectInterviewLocationName
                            }
                            setSelectInterviewLocationAddress={
                              setSelectInterviewLocationAddress
                            }
                            setSelectInterviewLocationMapUrl={
                              setSelectInterviewLocationMapUrl
                            }
                            setCandidateListId={setCandidateListId}
                            setSelectedInterviewLocationId={
                              setSelectedInterviewLocationId
                            }
                            handleToolTipInterviewDetail={
                              setToolTipInterviewDetail
                            }
                            handleToolTipCalenderChoose={
                              setToolTipToolTipCalenderChoose
                            }
                            handleToolTipReserved={
                              setToolTipReserved
                            }
                            setCurrentReservationList={
                              setCurrentReservationList
                            }
                            toolTipReserved={toolTipReserved}
                            currentReservationList={currentReservationList}
                          />
                        ) : null}
                        {/* 日程調整ブロック */}

                        {/* メッセージブロック */}
                        <MessageInput
                          form={formik}
                          targetList={targetList}
                          isInitialized={isInitialized}
                          runConfirm={runConfirm}
                          setInitialValueSubject={setInitialValueSubject}
                          setInitialValueBody={setInitialValueBody}
                          changeFile={changeFile}
                          deleteFile={deleteFile}
                          setIsFixedSubject={setIsFixedSubject}
                          forbiddenWords={forbiddenWords}
                        />
                        {/* メッセージブロック */}

                        {/* 送信日時ブロック */}
                        <SendTime
                          form={formik}
                          destinationSelection={destinationSelection}
                          isInitialized={isInitialized}
                          interviewWebUrlSendFlag={interviewWebUrlSendFlag}
                          setTimeState={setTimeState}
                        />
                        {/* 送信日時ブロック */}

                        {/* お知らせ通知ブロック 「マイナビのみ」の場合に表示 */}
                        {recruitmentManagementDivision ===
                          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
                        destinationSelection ===
                          magiContants.DESTINATION_DIVISION_MYNAVI_ONLY ? (
                          <Reminder form={formik} />
                        ) : null}
                        {/* お知らせ通知ブロック */}
                        <ErrorFocus />
                      </div>
                      <footer
                        className={'modal-section-btn modal-message-btn'}
                        style={{ color: '#000' }}>
                        <div className={'modal-message-btn__body'}>
                          <button
                            className={'btn__message-cancel'}
                            type='button'
                            onClick={handleClose}>
                            キャンセル
                          </button>
                          <button
                            type='button'
                            className={'btn__message-save'}
                            onClick={() => handleSaveDraft(formik)}>
                            下書き保存
                          </button>
                          <button className={'btn__message-send'} type='submit'>
                            メッセージ送信
                          </button>
                        </div>
                      </footer>
                    </div>
                  </Form>
                )}
              />

              <Dialog open={hasCloseWindowByDraft} disableScrollLock={true}>
                <div className='dialog-content' style={{ width: '100%' }}>
                  <div className={'dialog-section'}>
                    {textMap['draftSaveComplete'].message}
                  </div>
                  <DialogActions
                    className={'dialog-section-btn'}
                    style={{ justifyContent: 'center' }}>
                    <button
                      onClick={handleDraftClose}
                      className='daialogOnlyConfirmBtn'>
                      {textMap['draftSaveComplete'].submit}
                    </button>
                  </DialogActions>
                </div>
              </Dialog>
              <Dialog
                open={confirmDialogDraftCandidateOpen}
                disableScrollLock={true}>
                <div className='dialog-content' style={{ width: '100%' }}>
                  <div className={'dialog-section'}>
                    {textMap['draftCandidateConfirm'].message}
                  </div>
                  <DialogActions
                    className={'dialog-section-btn'}
                    style={{ justifyContent: 'center' }}>
                    <button
                      onClick={handleCandidateClose}
                      className='daialogOnlyConfirmBtn'>
                      {textMap['draftCandidateConfirm'].submit}
                    </button>
                  </DialogActions>
                </div>
              </Dialog>

              <Dialog open={confirmDialogOpen} disableScrollLock={true}>
                <LoadingDialog className='loadingOverlay' />
                <div className='dialog-content' style={{ width: '100%' }}>
                  <div className={'dialog-section'}>
                    {textMap[type].message}
                  </div>
                  <DialogActions className={'dialog-section-btn'}>
                    <button className='daialogCancelBtn' onClick={handleCancel}>
                      {textMap[type].cancel}
                    </button>
                    <button
                      className='daialogConfirmBtn'
                      onClick={handleConfirm}
                      color='primary'>
                      {textMap[type].submit}
                    </button>
                  </DialogActions>
                </div>
              </Dialog>

              <Dialog open={confirmDialog060Open} disableScrollLock={true}>
                <div className='dialog-content' style={{ width: '100%' }}>
                  <div className={'dialog-section'}>
                    {textMap['060'].message}
                  </div>
                  <DialogActions className={'dialog-section-btn'}>
                    <button
                      onClick={handleCancel060}
                      className='daialogCancelBtn'>
                      {textMap[type].cancel}
                    </button>
                    <button
                      onClick={handleConfrim060}
                      className='daialogConfirmBtn'>
                      {textMap[type].submit}
                    </button>
                  </DialogActions>
                </div>
              </Dialog>
            </div>
            {mCAZS091Open && !existsPreviewData() && <Loading />}
            <MCAZM091
              open={mCAZS091Open && mCAZS080Open && existsPreviewData()}
              onClose={handleOnClose}
              registerValue={registerValue}
              attachment={attachmentFileValue}
              isFixedSubject={isFixedSubject}
              setMCAZS101Open={setMCAZS101Open}
              setConfState={setConfState}
              minimization={minimization}
              setMinimization={setMinimization}
              onDialogClose={onDialogClose}
            />
           {mCAZS130Open &&(
            <MCAZM130
              selectedInterviewLocationId={selectedInterviewLocationId}
              candidateListId={candidateListId}
              updateFormModal={mCAZS130Open}
              setUpdateFormModal={setMCAZS130Open}
              setSelectInterviewLocationId={setSelectInterviewLocationId}
              setSelectInterviewLocationName={setSelectInterviewLocationName}
              setSelectInterviewLocationAddress={
                setSelectInterviewLocationAddress
              }
              setSelectInterviewLocationMapUrl={
                setSelectInterviewLocationMapUrl
              }
            />
           )}
          </>
        ) : (
          <>
            {mCAZS091Open && !existsPreviewData() && isNotAdoptedNotification && (
              <>
                <div>
                  <div className={classes.loading}>
                    {magiContants.MESSAGEINFO_LOADING}
                  </div>
                </div>
                <Loading />
              </>
            )}
            <MCAZM091
              open={
                mCAZS091Open &&
                existsPreviewData() &&
                isNotAdoptedNotification &&
                mCAZS080Open
              }
              onClose={handleOnClose}
              registerValue={registerValue}
              attachment={attachmentFileValue}
              isFixedSubject={isFixedSubject}
              setMCAZS101Open={setMCAZS101Open}
              setConfState={setConfState}
              minimization={minimization}
              setMinimization={setMinimization}
              onDialogClose={onDialogClose}
            />
          </>
        )}
        {/* モーダルクローズ */}
        <svg
          width='25'
          height='25'
          viewBox='0 0 50 50'
          fill='none'
          display='none'>
          <defs>
            <path
              id='form__modal_close'
              data-name='form__modal_close'
              d='M43.747,50,25,31.249,6.249,50,0,43.746,18.751,25,0,6.249,6.249,0,25,18.751,43.747,0,50,6.249,31.249,25,50,43.746Z'
              transform='translate(0 0)'
            />
          </defs>
          <use xlinkHref='#form__modal_close'></use>
        </svg>
        {/* モーダルクローズ */}

        {/* 情報アイコン（面接内容） */}
        {toolTipInterviewDetail && (
          <div className='tooltip is-tooltip-show'>
            {'応募者に通知する面接内容（「一次面接」「最終面接」など）'}
          </div>
        )}

        {/* 情報アイコン（日程選択） */}
        {toolTipToolTipCalenderChoose && (
          <div className='tooltip is-tooltip-show'>
            {`面接日時は現在時刻から90日後まで指定可能です。`}
          </div>
        )}

        {/* 情報アイコン（予定あり情報） */}
        {toolTipReserved && currentReservationList.length > 0 && (
          <div className='tooltip is-tooltip-show'>
             {currentReservationList.map(
              (i: any, index: number) => (
                <React.Fragment key={index}>
                  {index !== 0 && <br />}
                  <p style={{fontWeight: 'bold'}}>{i.name}</p>
                  <p>
                    {String(i.start).split('T')[1]}～{String(i.end).split('T')[1]}
                  </p>
                  <p>{i.location}</p>
                  <p>担当者：{i.charge}</p>
                </React.Fragment>
              )
            )}
          </div>
        )}
      </>
    </>
  )
}

export default MCAZM080
export interface FormHandler extends FormikProps<typeof initialValues> {}
