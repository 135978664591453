import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty, onKeyDown, stringToDateTime } from 'common/generalUtil'
import { LoadingDialog } from 'components'
import { ErrorFocus } from 'componentsHsc'
import Loading from 'componentsHsc/Loading'
import { Form, Formik, FormikProps } from 'formik'
import MCAZS091 from 'pages/MCAZS091'
import MCAZS130 from 'pages/MCAZS130'
import React, { useEffect, useState, RefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import {
  changeConfirmState,
  close060ConfirmModal,
  closeConfirmModal,
  confirm,
  getInit,
  getNotAdoptedNotification,
  getPreview,
  inputAttachment,
  inputBody,
  inputInterviewDetail,
  inputNotificationMailAddress,
  inputNotificationSelect,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputSubject,
  saveDraft,
  setConfirmRequest,
  setDraftReqeust,
  setIsDraft,
  setRecruitmentManagementDivision,
  changeTransmission,
  inputSendTimer,
  clearCandidate,
  inputManipulationSelection
} from 'reducers/messageSendReducer'
import { MCAZS080MessageConfirmRequest } from 'types/MCAZS080/MCAZS080MessageConfirmRequest'
import { MCAZS080MessageDraftRequest } from 'types/MCAZS080/MCAZS080MessageDraftRequest'
import { MCAZS080MessagePreviewRequest } from 'types/MCAZS080/MCAZS080MessagePreviewRequest'
import { magiContants, validationMessageSet } from 'utils/contants'
import { miscRegexSet } from 'utils/regex'
import MCAZS080Validation from 'validations/MCAZS080/MCAZS080MessageConfirmRequestValidation'
import * as yup from 'yup'
import {
  InputValues,
  messageInitRequest,
  notAdoptedNotificationRequest,
  targetTextAreas,
  textMap,
} from './formConfig'
import MessageInput from './MessageInput'
import Reminder from './Reminder'
import ScheduleAdjustment from './ScheduleAdjustment'
import SendTarget from './SendTarget'
import SendTime from './SendTime'
// edge05 74716 start
import MessageTemplate from './MessageTemplate'
// edge05 74716 end

import * as viewConfig from 'pages/MCAZS080/viewConig'
import * as viewConfig091 from 'pages/MCAZS091/viewConig'

const localStorageName = 'messageInputRequest'

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  root: {
    display: 'block',
    marginTop: '2px',
    marginLeft: '2px',
    marginRight: '2px',
    padding: '5px',
  },
  section: {
    minWidth: '0 !important',
    '& div.ta-unset': {
      textAlign: 'unset',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      marginBottom: '20px',
      '& button': {
        width: 'auto',
        position: 'absolute',
        left: '0',
        top: '-48px',
        margin: '0',
      },
      '& .btn.high': {
        left: 'auto',
      },
    },
  },
  loading: {
    marginTop: '200px',
    textAlign: 'center',
    fontSize: '2.1rem',
  },
}))

let initialValues: InputValues = {
  templateName: { label: '', value: '' },
  sendTarget: 'onlyMynavi',
  sendTime: null,
  sendTimeTransmission: '',
  reminder: '',
  reminderSelect: magiContants.NOTIFICATION_DESIRED,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  interviewDetail: '',
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  barcodeDisplay: '',
  repliesLimit: null,
  sendTimeType: '',
}

type Props = {
  onDialogClose?: Function
  isCompletedSending: boolean
  registerValue: viewConfig.RegisterValue
  setConfState: (value: viewConfig091.MessageConfVal) => void
  setMCAZS101Open: (value: boolean) => void
  setConfirm1Open: (value: boolean) => void
  modalHeight: number
  dialogRef: RefObject<HTMLDialogElement>
}

const MCAZS080 = ({
  onDialogClose,
  isCompletedSending,
  registerValue,
  setConfState,
  setMCAZS101Open,
  setConfirm1Open,
  modalHeight,
  dialogRef
}: Props) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialog060Open, setConfirmDialog060Open] = useState(false)
  const [
    confirmDialogDraftCandidateOpen,
    setConfirmDialogDraftCandidateOpen,
  ] = useState(false)
  const [type, setType] = useState<keyof typeof textMap>('confirm')
  const dispatch = useDispatch()
  const classes = useStyles()
  const [attachmentFileValue, setAttachmentFileValue] = useState<any[]>([])
  const snackbar = useSelector((state: RootState) => state.snackbar)
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  const targetList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.sendTarget
  )
  const entryBoxList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.messageInput.entryBox
  )
  const seminarList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.messageInput.seminar
  )
  const destinationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .destinationSelection
  )
  const hasCloseWindow = useSelector(
    (state: RootState) => state.messageSend.hasCloseWindow
  )
  const hasCloseWindowByDraft = useSelector(
    (state: RootState) => state.messageSend.hasCloseWindowByDraft
  )
  const messageInput = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.messageInput
  )
  const sendTimeInput = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.sendTimeInput
  )

  const notification = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.notification
  )
  const interviewSchedule = useSelector(
    (state: RootState) => state.messageSend.screenDisplayItems.interviewSchedule
  )
  const runConfirm = useSelector(
    (state: RootState) => state.messageSend.runConfirm
  )
  const openConfirm = useSelector(
    (state: RootState) => state.messageSend.openConfirm
  )
  const open060ConfirmModal = useSelector(
    (state: RootState) => state.messageSend.open006ConfirmModal
  )
  const manipulationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule
        .manipulationSelection
  )
  const isDraft = useSelector((state: RootState) => state.messageSend.isDraft)
  const isInitialized = useSelector(
    (state: RootState) => state.messageSend.isInitialized
  )
  const selectedTemplateSettingId = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.title.selectedTemplateSettingId
  )
  const forbiddenWords = useSelector(
    (state: RootState) => state.messageSend.initResult.forbiddenWord
  )
  const attachmentFileRegistrationMax = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.attachmentFileRegistrationMax
  )
  const mynaviAttachedOptionMax = useSelector(
    (state: RootState) =>
      state.messageSend.initResult.mynaviAttachedOptionRegistrationMax
  )
  const readableFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.readableFlag
  )
  const [mCAZS091Open, setMCAZS091Open] = useState(false)
  const [isOnlyAnonymous, setIsOnlyAnonymous] = useState(false)
  const [containsOtherSelection, setContainsOtherSelection] = useState(false)
  const [
    initialValueSenderCompanyName,
    setInitialValueSenderCompanyName,
  ] = useState('')
  const [
    initialValueSenderMailAddress,
    setInitialValueSenderMailAddress,
  ] = useState('')
  const [initialValueReminder, setInitialValueReminder] = useState('')
  const [initialValueReminderSelect, setInitialValueReminderSelect] = useState(
    magiContants.NOTIFICATION_DESIRED
  )
  const [initialValueSubject, setInitialValueSubject] = useState('')
  const [initialValueBody, setInitialValueBody] = useState('')
  const [
    initialValueInterviewDetail,
    setInitialValueInterviewDetail,
  ] = useState('')
  const [initialValueHomepageTitle1, setInitialValueHomepageTitle1] = useState(
    ''
  )
  const [initialValueHomepageTitle2, setInitialValueHomepageTitle2] = useState(
    ''
  )
  const [initialValueHomepageTitle3, setInitialValueHomepageTitle3] = useState(
    ''
  )
  const [initialValueHomepageUrl1, setInitialValueHomepageUrl1] = useState('')
  const [initialValueHomepageUrl2, setInitialValueHomepageUrl2] = useState('')
  const [initialValueHomepageUrl3, setInitialValueHomepageUrl3] = useState('')
  const [initialValueRepliesLimit, setInitialValueRepliesLimit] = useState('')
  const [initialSendTimeType, setInitialSendTimeType] = useState('')
  const [mCAZS130Open, setMCAZS130Open] = useState(false)
  const [
    selectedInterviewLocationId,
    setSelectedInterviewLocationId,
  ] = useState('0')
  const [candidateListId, setCandidateListId] = useState(0)
  const [selectInterviewLocationId, setSelectInterviewLocationId] = useState(
    '0'
  )
  const [
    selectInterviewLocationName,
    setSelectInterviewLocationName,
  ] = useState('')
  const [
    selectInterviewLocationAddress,
    setSelectInterviewLocationAddress,
  ] = useState('')
  const [
    selectInterviewLocationMapUrl,
    setSelectInterviewLocationMapUrl,
  ] = useState('')
  const [isFixedSubject, setIsFixedSubject] = useState(false)
  const previewDataList = useSelector(
    (state: RootState) => state.messageSend.previewDataList
  )
  const isNotAdoptedNotification = useSelector(
    (state: RootState) => state.messageSend.isNotAdoptedNotification
  )
  const interviewWebUrlSendFlag = useSelector(
    (state: RootState) => state.messageSend.initResult.interviewWebUrlSendFlag
  )
  const [MCAZS080Open, setMCAZS080Open] = useState(true)

  const [current, setCurrent] = useState<FormHandler>()

  const [timeState, setTimeState] = useState<boolean>(false)

  const [isReplyMessage, setIsReplyMessage] = useState<boolean>(false)

  const reminderYup = yup.object().shape({
    reminder: yup
      .string()
      .notRequired()
      .test('isEmails', validationMessageSet.email(), (value: string) => {
        if (value) {
          const isValid = value
            .split(',')
            .map(email => email.trim())
            .filter(v => !isEmpty(v))
            .find(
              v =>
                !yup
                  .string()
                  .test('isMail', validationMessageSet.email, (value: any) =>
                    miscRegexSet.email.test(value)
                  )
                  .isValidSync(v)
            )
          return !isValid
        } else {
          return true
        }
      })
      .max(160, validationMessageSet.maxLength),
  })

  const addAttachmentFileNameYup = yup.object().shape({
    addAttachmentFileName: yup.string(),
  })

  const staffNameYup = yup.object().shape({
    staffName: yup.string(),
  })

  const subjectYup = yup.object().shape({
    subject: yup
      .string()
      .test('required', validationMessageSet.required, (value: string) => {
        if (isEmpty(value)) {
          return false
        }
        return !isEmpty(value.trim())
      })
      .max(200, validationMessageSet.maxLength)
      .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
        miscRegexSet.halfWidth.test(value)
      ),
  })

  const bodyYup = yup.object().shape({
    body: yup
      .string()
      .test('required', validationMessageSet.required, (value: string) => {
        if (isEmpty(value)) {
          return false
        }
        return !isEmpty(value.trim())
      })
      .max(
        destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
          ? 2000
          : 1959,
        validationMessageSet.maxLength
      )
      .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
        miscRegexSet.halfWidth.test(value)
      ),
  })

  const repliesLimitYup = yup.object().shape({
    repliesLimit: yup
      .string()
      .test('isDate', validationMessageSet.date, (value: any) =>
        miscRegexSet.date.test(value)
      ),
  })

  initialValues = {
    templateName: { label: '', value: '' },
    sendTarget: 'onlyMynavi',
    sendTime: null,
    sendTimeTransmission: '',
    reminder: initialValueReminder,
    reminderSelect: initialValueReminderSelect,
    senderCompanyName: initialValueSenderCompanyName,
    senderMailAddress: initialValueSenderMailAddress,
    subject: initialValueSubject,
    body: initialValueBody,
    interviewDetail: initialValueInterviewDetail,
    homepageTitle1: initialValueHomepageTitle1,
    homepageTitle2: initialValueHomepageTitle2,
    homepageTitle3: initialValueHomepageTitle3,
    homepageUrl1: initialValueHomepageUrl1,
    homepageUrl2: initialValueHomepageUrl2,
    homepageUrl3: initialValueHomepageUrl3,
    barcodeDisplay: '',
    repliesLimit: initialValueRepliesLimit,
    sendTimeType: initialSendTimeType
  }

  useEffect(() => {
    if (destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
      dispatch(inputManipulationSelection(magiContants.SELECTION_NUMBER_NOT_PRESENT))
      dispatch(clearCandidate())
    }
  }, [destinationSelection])

  useEffect(() => {
    if (isInitialized) {
      setInitialValueReminder(
        notification.notificationMailAddress
          ? notification.notificationMailAddress
          : ''
      )
      setInitialValueReminderSelect(notification.notificationSelect)
      setInitialValueSenderCompanyName(
        messageInput.senderCompanyName ? messageInput.senderCompanyName : ''
      )
      setInitialValueSenderMailAddress(
        messageInput.senderMailAddress ? messageInput.senderMailAddress : ''
      )
      setInitialValueSubject(messageInput.subject ? messageInput.subject : '')
      setInitialValueBody(messageInput.body ? messageInput.body : '')
      setInitialValueInterviewDetail(
        interviewSchedule.interviewDetail
          ? interviewSchedule.interviewDetail
          : ''
      )
      setInitialValueHomepageTitle1(
        messageInput.homePage[0] ? messageInput.homePage[0].homePageTitle : ''
      )
      setInitialValueHomepageTitle2(
        messageInput.homePage[1] ? messageInput.homePage[1].homePageTitle : ''
      )
      setInitialValueHomepageTitle3(
        messageInput.homePage[2] ? messageInput.homePage[2].homePageTitle : ''
      )
      setInitialValueHomepageUrl1(
        messageInput.homePage[0] ? messageInput.homePage[0].homePageUrl : ''
      )
      setInitialValueHomepageUrl2(
        messageInput.homePage[1] ? messageInput.homePage[1].homePageUrl : ''
      )
      setInitialValueHomepageUrl3(
        messageInput.homePage[2] ? messageInput.homePage[2].homePageUrl : ''
      )
      setInitialValueRepliesLimit(
        messageInput.repliesLimit ? messageInput.repliesLimit : ''
      )
      setInitialSendTimeType(
        sendTimeInput.transmission ? sendTimeInput.transmission : ''
      )
    }
  }, [isInitialized, selectedTemplateSettingId])

  useEffect(() => {
    if (
      !isCompletedSending &&
      snackbar.message === '' &&
      snackbar.type === ''
    ) {
      if (hasCloseWindow) {
        if (onDialogClose) {
          onDialogClose()
        }
      }
      if (
        recruitmentManagementDivision ===
        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 ||
        !isNotAdoptedNotification
      ) {
        dispatch(changeConfirmState(false))
        dispatch(closeConfirmModal())
        setMCAZS091Open(false)
        setConfirm1Open(false)
        setMCAZS080Open(true)
      }
    }
  }, [snackbar])

  useEffect(() => {
    dispatch(setRecruitmentManagementDivision(recruitmentManagementDivision))
    const messageInputRequest = localStorage.getItem(localStorageName)
    if (messageInputRequest) {
      const newParams = JSON.parse(messageInputRequest)
      if (newParams.notAdoptedNotificationType) {
        dispatch(
          getNotAdoptedNotification({
            ...notAdoptedNotificationRequest,
            ...newParams,
          })
        )
      } else {
        dispatch(getInit({ ...messageInitRequest, ...newParams }))
      }
      if (newParams.replyMessageSendId) {
        setIsReplyMessage(true)
      }
      localStorage.removeItem(localStorageName)
    } else {
      dispatch(getInit(messageInitRequest))
    }
  }, [dispatch])

  useEffect(() => {
    if (runConfirm) {
      const formData: MCAZS080MessageConfirmRequest = confirmRequest
      const files: any[] = attachmentFileValue
      const entryTypeList: string[] = targetList.map(item => item.entryType)
      const viewEndTimeList = targetList.map(item => item.viewEndTime)
      dispatch(
        confirm({
          formData,
          files,
          entryTypeList,
          viewEndTimeList,
          forbiddenWords,
          entryBoxList,
          seminarList,
          attachmentFileRegistrationMax,
          mynaviAttachedOptionMax,
          sendToNonMemberCheck,
          readableFlag,
        })
      )
    }
  }, [runConfirm])

  useEffect(() => {
    if (openConfirm) {
      setMCAZS091Open(true)
      setConfirm1Open(true)
      setMCAZS080Open(false)
    } else {
      setMCAZS091Open(false)
      setConfirm1Open(false)
      setMCAZS080Open(true)

    }
  }, [openConfirm])

  useEffect(() => {
    if (mCAZS091Open === false) {
      dispatch(changeConfirmState(false))
      dispatch(closeConfirmModal())
    }
  }, [mCAZS091Open])

  useEffect(() => {
    if (open060ConfirmModal) {
      setConfirmDialog060Open(true)
    }
  }, [open060ConfirmModal])

  useEffect(() => {
    if (isNotAdoptedNotification) {
      const request: MCAZS080MessagePreviewRequest = {
        companyId: '',
        entryId: targetList.map(item => item.entryId),
        interviewScheduleAdjustmentId: null,
        interviewScheduleId: null,
      }
      dispatch(getPreview({ formData: request }))
      dispatch(setConfirmRequest())
    }
  }, [isNotAdoptedNotification])

  const confirmRequest = useSelector(
    (state: RootState) => state.messageSend.confirmRequest
  )

  const sendToNonMemberCheck = useSelector(
    (state: RootState) => state.messageSend.registerValue.sendToNonMember
  )
  const draftRequest = useSelector(
    (state: RootState) => state.messageSend.draftRequest
  )

  const changeFile = (event: any) => {
    const index: number = Number(event.target.id.substring('file'.length))
    const file: any = document.getElementById(event.target.id)
    const fileName: string = event.target.value.substring(
      event.target.value.lastIndexOf('\\') + 1
    )
    if (!isEmpty(fileName)) {
      dispatch(inputAttachment({ index, fileName }))
      attachmentFileValue[index] = file.files[0]
      setAttachmentFileValue(attachmentFileValue)
    }
  }

  const deleteFile = (index: number) => {
    let newFileList = attachmentFileValue
    newFileList.splice(index, 1)
    setAttachmentFileValue(newFileList)
  }

  const clearFile = () => {
    const emptyList: any[] = []
    setAttachmentFileValue(emptyList)
  }

  const handleOnClose = () => {
    setMCAZS091Open(false)
    setConfirm1Open(false)
    setMCAZS080Open(true)
  }

  const setTime = (index: any) => {
    let anchorElement = document.getElementById('id_' + index)
    if (anchorElement) {
      // 如果对应id的锚点存在，就跳转到锚点
      anchorElement.scrollIntoView()
    }
  }

  const setAttachment = (index: any) => {
    let anchorElement = document.getElementById('f' + index)
    if (anchorElement) {
      // 如果对应id的锚点存在，就跳转到锚点
      anchorElement.scrollIntoView()
    }
  }

  const candidateDate = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.interviewSchedule.candidateDate
  )

  // 「確認する」ボタン押下
  const handleSubmit = (values: InputValues) => {
    let minutesFlag = true
    let attachmentFlag = true
    let indexVal: any[] = []
    candidateDate.forEach((item, index) => {
      const interviewStartTime =
        item.interviewStartTime == null || item.interviewStartTime == ''
          ? null
          : stringToDateTime(item.interviewStartTime)
      const interviewLocationId = item.interviewLocationId
      if (
        (!isEmpty(interviewLocationId) && !interviewStartTime) ||
        (interviewStartTime &&
          (interviewStartTime.getHours() < 9 ||
            interviewStartTime.getHours() > 22)) ||
        (interviewStartTime &&
          interviewStartTime.getMinutes() % magiContants.ZS080_TIMEINTERVALS !==
          0)
      ) {
        indexVal.push(index)
        minutesFlag = false
      } else {
        if (!staffNameYup.isValidSync({ staffName: item.interviewStaffName })) {
          indexVal.push(index)
          minutesFlag = false
        }
      }
    })

    if (minutesFlag) {
      messageInput.attachment.forEach((item, index) => {
        if (
          !addAttachmentFileNameYup.isValidSync({
            addAttachmentFileName: item.attachmentFileName,
          })
        ) {
          indexVal.push(index)
          attachmentFlag = false
        }
      })
    }

    if (minutesFlag && attachmentFlag) {
      dispatch(inputBody({ target: targetTextAreas.body, value: values.body }))
      dispatch(
        inputSubject({ target: targetTextAreas.subject, value: values.subject })
      )
      dispatch(inputSenderCompanyName({ value: values.senderCompanyName }))
      dispatch(inputSenderMailAddress({ value: values.senderMailAddress }))
      dispatch(inputNotificationMailAddress({ value: values.reminder }))
      dispatch(inputNotificationSelect({ value: values.reminderSelect }))
      dispatch(inputInterviewDetail({ value: values.interviewDetail }))
      dispatch(setConfirmRequest())
    } else if (!minutesFlag) {
      setTime(indexVal[0])
    } else {
      setAttachment(indexVal[0])
    }
  }

  // 「キャンセルする」ボタン押下
  const handleClose = () => {
    setType('cancel')
    setConfirmDialogOpen(true)
  }

  // 「下書き保存」ボタン押下
  const handleSaveDraft = (form: FormHandler) => {
    setCurrent(form)
    dispatch(setIsDraft(true))

    form.setTouched({
      subject: true,
      body: true,
      homepageTitle1: true,
      homepageTitle2: true,
      homepageTitle3: true,
      homepageUrl1: true,
      homepageUrl2: true,
      homepageUrl3: true,
      senderCompanyName: true,
      senderMailAddress: true,
      reminder: true,
      interviewDetail: true,
      repliesLimit: true,
    })
    if (manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
      if (
        !form.values.interviewDetail ||
        form.values.interviewDetail === '' ||
        form.errors.interviewDetail
      ) {
        dispatch(setIsDraft(false))
        return false
      }
      // 面接日程 有無チェック
      if (!candidateDate.some(item => !isEmpty(item.interviewLocationId))) {
        setConfirmDialogDraftCandidateOpen(true)
        return false
      }
    }
    if (
      !repliesLimitYup.isValidSync({ repliesLimit: form.values.repliesLimit })
    ) {
      dispatch(setIsDraft(false))
      return false
    }
    let errFlag = false
    candidateDate.forEach(item => {
      const interviewStartTime =
        item.interviewStartTime == null || item.interviewStartTime == ''
          ? null
          : stringToDateTime(item.interviewStartTime)
      const interviewLocationId = item.interviewLocationId
      if (
        (!isEmpty(interviewLocationId) && !interviewStartTime) ||
        (interviewStartTime &&
          (interviewStartTime.getHours() < 9 ||
            interviewStartTime.getHours() > 22)) ||
        (interviewStartTime &&
          interviewStartTime.getMinutes() % magiContants.ZS080_TIMEINTERVALS !==
          0)
      ) {
        // 候補日のチェック
        errFlag = true
      } else if (
        !staffNameYup.isValidSync({ staffName: item.interviewStaffName })
      ) {
        // 担当者のチェック
        errFlag = true
      }
    })
    if (errFlag) {
      dispatch(setIsDraft(false))
      return false
    }
    // 通知先メールアドレスチェック
    if (form.values.reminder !== '') {
      if (reminderYup.isValidSync({ reminder: form.values.reminder })) {
        form.errors.reminder = ''
      }
    }
    if (form.errors.reminder && form.errors.reminder !== '') {
      dispatch(setIsDraft(false))
      return false
    }
    if (form.values.subject !== '') {
      if (subjectYup.isValidSync({ subject: form.values.subject })) {
        form.errors.subject = ''
      }
    }

    if (form.values.body !== '') {
      if (bodyYup.isValidSync({ body: form.values.body })) {
        form.errors.body = ''
      }
    }
    if (
      (!form.errors.subject || form.errors.subject === '') &&
      (!form.errors.body || form.errors.body === '')
    ) {
      setType('draft')
      if (form.values.sendTimeType === '' && !timeState && !errFlag && !form.errors.reminder && form.errors.reminder === '') {
        dispatch(changeTransmission({ value: '1' }))
        dispatch(inputSendTimer({ value: null }))
      }
      dispatch(
        inputBody({ target: targetTextAreas.body, value: form.values.body })
      )
      dispatch(
        inputSubject({
          target: targetTextAreas.subject,
          value: form.values.subject,
        })
      )
      dispatch(inputSenderCompanyName({ value: form.values.senderCompanyName }))
      dispatch(inputSenderMailAddress({ value: form.values.senderMailAddress }))
      dispatch(inputNotificationMailAddress({ value: form.values.reminder }))
      dispatch(inputNotificationSelect({ value: form.values.reminderSelect }))
      dispatch(inputInterviewDetail({ value: form.values.interviewDetail }))
      dispatch(setDraftReqeust())
      setConfirmDialogOpen(true)
    } else {
      dispatch(setIsDraft(false))
    }
  }

  // 下書き完了ダイアログのOKボタン押下
  const handleDraftClose = () => {
    dispatch(setIsDraft(false))
    if (onDialogClose) {
      onDialogClose()
    }
  }

  // 下書き(面接日程0件エラー)のダイアログのOKボタン押下
  const handleCandidateClose = () => {
    dispatch(setIsDraft(false))
    setConfirmDialogDraftCandidateOpen(false)
  }

  const handleConfrim060 = () => {
    setConfirmDialog060Open(false)
    dispatch(changeConfirmState(false))
    dispatch(closeConfirmModal())
    dispatch(close060ConfirmModal())
    setMCAZS091Open(true)
    setConfirm1Open(true)
    setMCAZS080Open(false)
  }

  const handleCancel060 = () => {
    setConfirmDialog060Open(false)
    dispatch(changeConfirmState(false))
    dispatch(closeConfirmModal())
    dispatch(close060ConfirmModal())
  }

  // 「いいえ」ボタン押下
  const handleCancel = () => {
    setConfirmDialogOpen(false)
    dispatch(setIsDraft(false))
    if (current) {
      dispatch(changeTransmission({ value: current.values.sendTimeType }))
      dispatch(inputSendTimer({ value: current.values.sendTime }))
    }
  }

  // 「はい」ボタン押下
  const handleConfirm = () => {
    if (type === 'draft') {
      let formData: MCAZS080MessageDraftRequest = draftRequest
      let files: any[] = attachmentFileValue
      dispatch(
        saveDraft({
          formData,
          files,
          attachmentFileRegistrationMax,
          readableFlag,
        })
      )
      dispatch(setIsDraft(false))
    }
    if (type === 'cancel') {
      if (onDialogClose) {
        onDialogClose()
      }
    }
    setConfirmDialogOpen(false)
  }

  const existsPreviewData = () => {
    return previewDataList && previewDataList.length > 0
  }

  return (
    <div style={{ height: "100%" }} >
      {isInitialized ? (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={MCAZS080Validation({
              recruitmentManagementDivision: recruitmentManagementDivision,
              destinationSelection: destinationSelection,
              action: manipulationSelection,
              notificationSelect: notification.notificationSelect,
              containsOtherSelection: containsOtherSelection,
              isDraft: isDraft,
            })}
            render={formik => (
              <div style={{ display: MCAZS080Open ? '' : 'none' }}>
                <Form onKeyDown={onKeyDown}>
                  <main className={classes.root}>
                    <section className={`${classes.section} box-in-order`}>
                      {/* edge05 74716 start */}
                      {recruitmentManagementDivision ===
                        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 ? (
                        <MessageTemplate
                          form={formik}
                          clearFile={clearFile}
                          isInitialized={isInitialized}
                        />
                      ) : (
                        ''
                      )}
                      {/* edge05 74716 end */}
                      <SendTarget
                        isInitialized={isInitialized}
                        setIsOnlyAnonymous={setIsOnlyAnonymous}
                        isOnlyAnonymous={isOnlyAnonymous}
                        setContainsOtherSelection={setContainsOtherSelection}
                        companyName={messageInput.senderCompanyName}
                        mailAddress={messageInput.senderMailAddress}
                        clearFile={clearFile}
                        isReplyMessage={isReplyMessage}
                      />
                      {(destinationSelection ===
                        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY &&
                        !containsOtherSelection &&
                        //  [phase2] 日程調整エリア（※就職の場合はトルツメ）
                        recruitmentManagementDivision !=
                        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1) ||
                        //  [phase2] 日程調整エリア（※就職の場合はトルツメ） end
                        (recruitmentManagementDivision !=
                          magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 &&
                          interviewWebUrlSendFlag ===
                          magiContants.INTERVIEW_WEB_URL_SEND_FLAG) ? (
                        <ScheduleAdjustment
                          selectedInterviewLocationId={
                            selectedInterviewLocationId
                          }
                          candidateListId={candidateListId}
                          updateFormModal={mCAZS130Open}
                          selectInterviewLocationId={selectInterviewLocationId}
                          selectInterviewLocationName={
                            selectInterviewLocationName
                          }
                          selectInterviewLocationAddress={
                            selectInterviewLocationAddress
                          }
                          selectInterviewLocationMapUrl={
                            selectInterviewLocationMapUrl
                          }
                          setUpdateFormModal={setMCAZS130Open}
                          setSelectInterviewLocationId={
                            setSelectInterviewLocationId
                          }
                          setSelectInterviewLocationName={
                            setSelectInterviewLocationName
                          }
                          setSelectInterviewLocationAddress={
                            setSelectInterviewLocationAddress
                          }
                          setSelectInterviewLocationMapUrl={
                            setSelectInterviewLocationMapUrl
                          }
                          setCandidateListId={setCandidateListId}
                          setSelectedInterviewLocationId={
                            setSelectedInterviewLocationId
                          }
                        />
                      ) : null}
                      <MessageInput
                        form={formik}
                        targetList={targetList}
                        isInitialized={isInitialized}
                        runConfirm={runConfirm}
                        setInitialValueSubject={setInitialValueSubject}
                        setInitialValueBody={setInitialValueBody}
                        changeFile={changeFile}
                        deleteFile={deleteFile}
                        setIsFixedSubject={setIsFixedSubject}
                        forbiddenWords={forbiddenWords}
                      />
                      <SendTime
                        form={formik}
                        destinationSelection={destinationSelection}
                        isInitialized={isInitialized}
                        interviewWebUrlSendFlag={interviewWebUrlSendFlag}
                        setTimeState={setTimeState}
                      />
                      {recruitmentManagementDivision ===
                        magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 &&
                        destinationSelection ===
                        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY ? (
                        <Reminder form={formik} />
                      ) : null}
                    </section>
                    <ErrorFocus />
                  </main>
                  <footer className='ta-unset footer'>
                    <button className='btn' type='button' onClick={handleClose}>
                      キャンセル
                    </button>
                    <button
                      type='button'
                      className='btn'
                      onClick={() => handleSaveDraft(formik)}>
                      下書き保存
                    </button>
                    <button className='btn high' type='submit'>
                      プレビューして送信
                    </button>
                  </footer>
                </Form>
              </div>
            )}
          />
          <Dialog open={hasCloseWindowByDraft}>
            <DialogTitle>{textMap['draftSaveComplete'].message}</DialogTitle>
            <DialogActions>
              <Button onClick={handleDraftClose} color='primary'>
                {textMap['draftSaveComplete'].submit}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={confirmDialogDraftCandidateOpen}>
            <DialogTitle>
              {textMap['draftCandidateConfirm'].message}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleCandidateClose} color='primary'>
                {textMap['draftCandidateConfirm'].submit}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={confirmDialogOpen}>
            <LoadingDialog className='overlay' />
            <DialogTitle>{textMap[type].message}</DialogTitle>
            <DialogActions>
              <Button onClick={handleCancel}>{textMap[type].cancel}</Button>
              <Button onClick={handleConfirm} color='primary'>
                {textMap[type].submit}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={confirmDialog060Open}>
            <DialogTitle>{textMap['060'].message}</DialogTitle>
            <DialogActions>
              <Button onClick={handleCancel060}>{textMap[type].cancel}</Button>
              <Button onClick={handleConfrim060} color='primary'>
                {textMap[type].submit}
              </Button>
            </DialogActions>
          </Dialog>
          {mCAZS091Open && !existsPreviewData() && <Loading />}
          <MCAZS091
            open={mCAZS091Open && existsPreviewData()}
            onClose={handleOnClose}
            registerValue={registerValue}
            attachment={attachmentFileValue}
            isFixedSubject={isFixedSubject}
            setMCAZS101Open={setMCAZS101Open}
            setConfState={setConfState}
            modalHeight={modalHeight}
            dialogRef={dialogRef}
          />
          <MCAZS130
            selectedInterviewLocationId={selectedInterviewLocationId}
            candidateListId={candidateListId}
            updateFormModal={mCAZS130Open}
            setUpdateFormModal={setMCAZS130Open}
            setSelectInterviewLocationId={setSelectInterviewLocationId}
            setSelectInterviewLocationName={setSelectInterviewLocationName}
            setSelectInterviewLocationAddress={
              setSelectInterviewLocationAddress
            }
            setSelectInterviewLocationMapUrl={setSelectInterviewLocationMapUrl}
          />
        </>
      ) : (
        <>
          {mCAZS091Open && !existsPreviewData() && isNotAdoptedNotification && (
            <>
              <div>
                <div className={classes.loading}>
                  {magiContants.MESSAGEINFO_LOADING}
                </div>
              </div>
              <Loading />
            </>
          )}
          <MCAZS091
            open={
              mCAZS091Open && existsPreviewData() && isNotAdoptedNotification
            }
            onClose={handleOnClose}
            registerValue={registerValue}
            attachment={attachmentFileValue}
            isFixedSubject={isFixedSubject}
            setMCAZS101Open={setMCAZS101Open}
            setConfState={setConfState}
            modalHeight={modalHeight}
            dialogRef={dialogRef}
          />
        </>
      )}
    </div>
  )
}

export default MCAZS080
export interface FormHandler extends FormikProps<typeof initialValues> { }
