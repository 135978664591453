/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import { RootState } from 'reducers'
import { isEmpty } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
import { useUtilStyles } from 'utils/styles'

import {
  deleteSendTarget,
  inputDestinationSelection,
  setIsOnlyAnonymousReducer,
  setIsOnlyNonMemberReducer,
  inputSendToNonMember,
  csvDownload,
} from 'reducers/messageSendReducer'
import { openModal } from 'reducers/messageReducer'
import { getMessage } from 'common/messageUtil'

const useStyles = makeStyles(theme => ({
  textOverflow: {
    maxWidth: '100%',
    maxHeight: 'calc( 1 * 1.5em)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  badge: {
    margin: `${theme.spacing(0, 0)} !important`,
    display: 'inline-flex !important',
    padding: '2px 0px',
    height: 'auto',
  },
  description: {
    marginLeft: '200px',
  },
  entryType: {
    backgroundColor: '#b6d3f0 !important',
    border: 'none !important',
  },
  mail: {
    '& td': {
      fontWeight: 'bold',
    },
  },
  // [phase2] start
  sendToNonMember: {
    minWidth: '155px',
  },
  radioGroup: {
    display: 'inlineBlock',
  },
  gridStyle: {
    minWidth: '458px',
  },
  // [phase2] end
  entryTypeTh: {
    width: '210px',
  },
  search: {
    flexBasis: '100% !important',
    marginTop: '-10px',
    marginBottom: '-3px',
  },
  searchCriteriaName: {
    fontWeight: 600,
  },
  mySearch: {
    flexBasis: '100% !important',
    marginTop: '15px',
    marginBottom: '-3px',
  },
  ellipsie: {
    width: '100%',
    backgroundColor: 'white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
    minHeight: '105px',
    maxHeight: '105px',
    cursor: 'auto!important',
    marginTop: '5px',
    '& div': {
      margin: '0px 0px 0px 5px',
    },
  },
  btn: {
    left: 'calc(100% - (30px * 11))',
    display: 'flex',
    height: '35px',
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    paddingLeft: '15px',
    paddingRight: '15px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
  },
  csvDownloadButton: {
    margin: '30px 0px 0px 0px',
    width: 220,
    color: '#ffffff',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#3380cc',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
      borderColor: '#3380cc',
    },
    minWidth: 150,
  },
  invisible: {
    fontSize: '1.8rem !important',
    textAlign: 'left',
    padding: '12px 0 12px 24px',
    backgroundColor: '#e5e5e5',
  },
  flagIconPosition: {
    position: 'absolute',
    right: '25px',
  },
  flagIcon: {
    width: '20px',
    height: 'auto',
    color: '#595959',
  },
  accordionRoot: {
    '&::before': {
      top: 0,
      height: 'inherit',
      position: 'inherit',
      backgroundColor: 'inherit',
    },
    color: 'inherit',
    position: 'inherit',
    backgroundColor: 'inherit',
  },
  accordionRounded: {
    '&:last-child': {
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
    '&:first-child': {
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    },
    borderRadius: 'initial',
  },
  accordionExpanded: {
    '&:last-child': {
      marginBottom: 'inherit !important',
    },
    margin: '0px !important',
  },
  accordionExpandedCondition: {
    '&:last-child': {
      marginBottom: 'inherit !important',
    },
    margin: '5px !important',
  },
  accordionExpandedList: {
    '&:last-child': {
      marginBottom: '10px !important',
    },
    margin: '10px !important',
  },
  accordionSummaryRoot: {
    display: 'flex',
    padding: 'initial',
    minHeight: 'initial',
    border: 'initial',
    cursor: 'initial',
    margin: 'initial',
    outline: 'initial',
    position: 'initial',
    alignItems: 'initial',
    borderRadius: 'initial',
    verticalAlign: 'initial',
    justifyContent: 'initial',
    textDecoration: 'initial',
    backgroundColor: 'initial',
    marginTop: '5px !important',
    marginBottom: '5px !important',
  },
  accordionSummaryRootList: {
    display: 'flex',
    padding: 'initial',
    minHeight: 'initial',
    border: 'initial',
    cursor: 'initial',
    margin: 'initial',
    outline: 'initial',
    position: 'initial',
    alignItems: 'initial',
    borderRadius: 'initial',
    verticalAlign: 'initial',
    justifyContent: 'initial',
    textDecoration: 'initial',
    backgroundColor: 'initial',
  },
  accordionSummaryExpanded: {
    minHeight: '0 !important',
    margin: '0 !important',
    marginTop: '5px !important',
  },
  accordionSummaryExpandedList: {
    minHeight: '0 !important',
    margin: '0 !important',
  },
  accordionSummaryContent: {
    margin: 'initial',
    display: 'inherit',
    flexGrow: 'initial',
    width: '100%',
  },
  accordionDetailRoot: {
    display: 'initial',
    padding: 0,
  },
}))

const senderTargetMax = 100

const memberTypeNameList = new Map([
  [magiContants.MEMBERS_TYPE_NON_MEMBER, 'マイナビ非会員'],
  [magiContants.MEMBERS_TYPE_MEMBER, 'マイナビ会員'],
  [magiContants.MEMBERS_TYPE_DEACTIVATED, '退会済み'],
])

const entryTypeList = new Map([
  ['01', '一括'],
  ['02', '個別'],
  ['03', 'セミナー'],
  ['04', 'イベント'],
  ['11', '通常応募'],
  ['12', 'スカウト応募'],
  ['13', 'セミナー・面談参加申込み'],
  ['14', '質問（お問い合わせ）'],
  ['15', '匿名質問'],
  ['16', '旧イベント応募'],
  ['17', 'イベント着席'],
  ['18', 'イベント応募'],
  ['19', '他ルート応募'],
  ['90', '取込'],
])

const progressTypeList = new Map([
  ['0', '未判定'],
  ['1', '判定中'],
  ['2', '参加'],
  ['3', '合格'],
  ['4', '不参加'],
  ['5', '不合格'],
  ['6', 'その他'],
])

interface Props {
  isInitialized: boolean
  setIsOnlyAnonymous: (value: boolean) => void
  isOnlyAnonymous: boolean
  setContainsOtherSelection: (value: boolean) => void
  companyName: string
  mailAddress: string
  isReplyMessage: boolean
  clearFile: () => void
}

const SendTarget = ({
  isInitialized,
  setIsOnlyAnonymous,
  isOnlyAnonymous,
  setContainsOtherSelection,
  companyName,
  mailAddress,
  clearFile,
  isReplyMessage,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const targetList = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.sendTarget
  )
  const destinationSelection = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .destinationSelection
  )
  // phase2 start
  const targetSendToNonMember = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .sendToNonMember
  )
  const searchCriteriaName = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .searchCriteriaName
  )
  const searchCriteria = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation.searchCriteria
  )
  const conversionMySearch = useSelector(
    (state: RootState) =>
      state.messageSend.screenDisplayItems.sendTargetConfirmation
        .conversionMySearch
  )

  const messageType = useSelector(
    (state: RootState) => state.messageSend.initResult.messageType
  )
  const [isOnlyNonMember, setIsOnlyNonMember] = useState(false)
  const [sendToNonMemberNum, setSendToNonMemberNum] = useState(false)
  const [isEmailOnly, setIsEmailOnly] = useState(false)
  const [isMynaviOnly, setIsMynaviOnly] = useState(false)

  const [] = useState(false)

  const [, setSenderFlg] = useState(true)

  // マイナビ選択時確認ダイアログキャンセル
  const clickMynaviOnlyCancel = () => {
    setIsMynaviOnly(false)
  }
  // マイナビ選択時
  const clickMynaviOnly = () => {
    setIsMynaviOnly(true)
  }

  // マイナビ選択時確認ダイアログOK
  const handleMynaviOnly = () => {
    // dispatch(openModal(getMessage(magiContants.MESSAGECODE_MCAZS080_063)))
    handleChange(magiContants.DESTINATION_DIVISION_MYNAVI_ONLY)
    setIsMynaviOnly(false)
    setSenderFlg(true)
  }

  // e-mail選択時確認ダイアログキャンセル
  const clickMynaviEmailOnlyCancel = () => {
    setIsEmailOnly(false)
  }
  // e-mail選択時
  const clickMynaviEmailOnly = () => {
    setIsEmailOnly(true)
  }

  // e-mail選択時確認ダイアログOK
  const handleMynaviEmailOnly = () => {
    //    dispatch(openModal(getMessage(magiContants.MESSAGECODE_MCAZS080_065)))
    handleChange(magiContants.DESTINATION_DIVISION_EMAIL_ONLY)
    setIsEmailOnly(false)
    setSenderFlg(false)
  }

  useEffect(() => {
    if (isInitialized && targetList && targetList.length > 0) {
      dispatch(
        inputDestinationSelection({
          target: destinationSelection,
          companyName: companyName,
          mailAddress: mailAddress,
          isInitialized: true,
        })
      )

      // 送信先がすべて非会員か
      if (
        targetList.some(
          item => item.mynaviMemberInfo === magiContants.MEMBERS_TYPE_MEMBER
        )
      ) {
        setIsOnlyNonMember(false)
        dispatch(setIsOnlyNonMemberReducer(false))
      } else {
        dispatch(
          inputDestinationSelection({
            target: magiContants.DESTINATION_DIVISION_EMAIL_ONLY,
            companyName: companyName,
            mailAddress: mailAddress,
            isInitialized: true,
          })
        )
        setIsOnlyNonMember(true)
        dispatch(setIsOnlyNonMemberReducer(true))
      }

      // 送信先がすべて匿名か
      if (messageType) {
        if (
          messageType.some(
            item =>
              item.messageType !== magiContants.MESSAGE_TYPE_NUMBER_ANONYMOUS
          )
        ) {
          setIsOnlyAnonymous(false)
          dispatch(setIsOnlyAnonymousReducer(false))
        } else {
          dispatch(
            inputDestinationSelection({
              target: magiContants.DESTINATION_DIVISION_MYNAVI_ONLY,
              companyName: companyName,
              mailAddress: mailAddress,
              isInitialized: true,
            })
          )
          setIsOnlyAnonymous(true)
          dispatch(setIsOnlyAnonymousReducer(true))
        }
      }

      // 送信先にその他の選考が含まれるか
      if (
        targetList.some(
          item =>
            item.selectionClassification ===
              magiContants.SELECTION_CLASSIFICATION_SEMINAR_APPLICATION ||
            item.selectionClassification ===
              magiContants.SELECTION_CLASSIFICATION_QUESTION ||
            item.selectionClassification ===
              magiContants.SELECTION_CLASSIFICATION_EVENT_SIT_DOWN
        )
      ) {
        setContainsOtherSelection(true)
      } else {
        setContainsOtherSelection(false)
      }

      targetList.map(applicant =>
        setTargetExpanedList(prevState => [
          ...prevState,
          { id: applicant.entryId, isExpanded: false },
        ])
      )
    }
  }, [isInitialized, targetList])
  // phase2 start
  useEffect(() => {
    if (targetSendToNonMember == magiContants.SEND_TO_NON_MEMBER_ONE) {
      setSendToNonMemberNum(true)
      dispatch(inputSendToNonMember(1))
    } else {
      setSendToNonMemberNum(false)
      dispatch(inputSendToNonMember(0))
    }
  }, [targetSendToNonMember])
  // phase2 end
  const handleChange = (value: string) => {
    // [phase2] start by zhangxp
    if (value === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
      dispatch(inputSendToNonMember(0))
      setSendToNonMemberNum(false)
    } else if (sendToNonMemberNum) {
      dispatch(inputSendToNonMember(1))
    } else {
      dispatch(inputSendToNonMember(0))
    }
    // [phase2] end by zhangxp
    dispatch(
      inputDestinationSelection({
        target: value,
        companyName: companyName,
        mailAddress: mailAddress,
        isInitialized: false,
      })
    )
    clearFile()
  }

  const handleDelete = (id: string) => () => {
    dispatch(deleteSendTarget({ id }))
  }

  // 「条件を表示する」ボタン押下
  const [, setDetailDialogOpen] = useState(false)

  const [targetExpanedList, setTargetExpanedList] = React.useState([
    { id: '', isExpanded: false },
  ])

  const handleExpandedChange = (id: string) => (
    event: React.ChangeEvent<{}>
  ) => {
    setTargetExpanedList(prevState =>
      prevState.map(obj =>
        obj.id === id ? { id: obj.id, isExpanded: !obj.isExpanded } : obj
      )
    )
    event.stopPropagation()
  }

  const isExpaned = (id: string) => {
    if (targetExpanedList) {
      const obj = targetExpanedList.find(item => item.id === id)
      if (obj) {
        return obj.isExpanded
      }
    }
    return false
  }

  const [sendTargetOpen, setSendTargetOpen] = React.useState(true)
  const handleSendTargetExpandedChange = () => {
    setSendTargetOpen(!sendTargetOpen)
  }

  const [sendOptionOpen, setSendOptionOpen] = React.useState(false)

  const [messageTargetDisplay, setMessageTargetDisplay] = useState(true)
  const [toolTip, setToolTip] = useState(false)

  const handleToolTip = () => {
    setToolTip(false)
  }

  return (
    <>
      {/* 送信先ブロック */}
      <section
        id='send-target'
        className='message-block'
        style={{ padding: '0px 10px 5px' }}>
        <div className='form__ttl_modal_3'>
          送信先
          <ClickAwayListener onClickAway={handleToolTip}>
            <svg
              className='form__body_ttl_icon mr10'
              focusable='false'
              viewBox='0 0 24 24'
              aria-hidden='true'
              data-js-tooltip-trigger='tooltip-message-1'
              onClick={() => setToolTip(!toolTip)}>
              <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
            </svg>
          </ClickAwayListener>
          <i className='modal-flow__badge_required'>必須</i>
        </div>
        <div className='form__body_modal mb0'>
          <div className='form__body_item_modal'>
            <FormControl>
              <div
                className='form__body_item_modal'
                style={{ flexWrap: 'nowrap' }}>
                <RadioGroup defaultValue={destinationSelection}>
                  <label className='form__label_side'>
                    <FormControlLabel
                      control={
                        <Radio
                          color='primary'
                          value={magiContants.DESTINATION_DIVISION_MYNAVI_ONLY}
                          checked={
                            destinationSelection ===
                            magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
                          }
                          disabled={isOnlyNonMember || isOnlyAnonymous || isReplyMessage}
                          onClick={clickMynaviOnly}
                          className='form__radio'
                          style={{ padding: 0 }}
                          icon={
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 21 21'
                              className='form__radio_svg_off'>
                              <use xlinkHref='#send_target_form__radio_off'></use>
                            </svg>
                          }
                          checkedIcon={
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 21 21'
                              className='form__radio_svg_on'>
                              <use xlinkHref='#send_target_form__radio_on'></use>
                            </svg>
                          }
                        />
                      }
                      label={
                        <span
                          className='form__radio_text'
                          style={{
                            fontWeight:
                              destinationSelection ===
                              magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
                                ? 'bold'
                                : 'unset',
                            width: '100%',
                          }}>
                          マイナビのみ
                        </span>
                      }
                    />
                  </label>
                </RadioGroup>
                <RadioGroup defaultValue={destinationSelection}>
                  <label className='form__label_side'>
                    <FormControlLabel
                      control={
                        <Radio
                          color='primary'
                          value={magiContants.DESTINATION_DIVISION_EMAIL_ONLY}
                          checked={
                            destinationSelection ===
                            magiContants.DESTINATION_DIVISION_EMAIL_ONLY
                          }
                          disabled={isOnlyNonMember || isOnlyAnonymous || isReplyMessage}
                          onClick={clickMynaviEmailOnly}
                          className='form__radio'
                          style={{ padding: 0 }}
                          icon={
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 21 21'
                              className='form__radio_svg_off'>
                              <use xlinkHref='#send_target_form__radio_off'></use>
                            </svg>
                          }
                          checkedIcon={
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 21 21'
                              className='form__radio_svg_on'>
                              <use xlinkHref='#send_target_form__radio_on'></use>
                            </svg>
                          }
                        />
                      }
                      label={
                        <span
                          className='form__radio_text'
                          style={{
                            fontWeight:
                              destinationSelection ===
                              magiContants.DESTINATION_DIVISION_EMAIL_ONLY
                                ? 'bold'
                                : 'unset',
                            width: '100%',
                          }}>
                          e-mailのみ
                        </span>
                      }
                    />
                  </label>
                </RadioGroup>
              </div>
            </FormControl>
          </div>
        </div>
      </section>
      {/* 送信先ブロック */}

      {/* 送信対象ブロック */}
      <Accordion
        className='message-target-display'
        onClick={e => e.stopPropagation()}
        expanded={sendTargetOpen}
        onChange={handleSendTargetExpandedChange}
        style={{ height: 'unset', wordBreak: 'break-all' }}>
        <AccordionSummary
          aria-label='Expand'
          aria-controls='serch-send-list-content'
          id='serch-send-list-id'
          onClick={e => e.stopPropagation()}
          className='message-target-display__head'
          style={{ padding: '0px', width: '100%' }}>
          <div
            className='message-target-display__head_top'
            style={{ width: '100%' }}>
            <h1>{'送信対象（' + targetList.length + '件）'}</h1>
            <div className={'message-target-display__switch'}>
              {sendTargetOpen ? (
                <i className='message-target-display__switch_none is-accordion-open' />
              ) : (
                <i className='message-target-display__switch_open is-accordion-open' />
              )}
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails
          className={
            sendTargetOpen
              ? 'message-target-display__body is-accordion-content-open'
              : 'message-target-display__body'
          }
          classes={{ root: classes.accordionDetailRoot }}>
          <>
            <div className='message-target-display__wrap'>
              <div className='message-target-display__inner'>
                {targetList &&
                  targetList.length > 1 &&
                  !isEmpty(searchCriteria) &&
                  (!conversionMySearch || conversionMySearch.length == 0) && (
                    <div className='message-target-conditions'>
                      <div className='message-target-conditions__head'>
                        検索条件
                      </div>
                      <div className='message-target-conditions__body'>
                        {!isEmpty(searchCriteriaName) ? (
                          <div>
                            選択しているMY検索
                            <span style={{fontWeight: 600, margin:'10px',wordBreak: 'break-all',}}>{searchCriteriaName}</span>
                          </div>
                        ) : null}
                        <div
                          className={
                            messageTargetDisplay
                              ? 'message-target-conditions__text text-overflow'
                              : 'message-target-conditions__text'
                          }
                          dangerouslySetInnerHTML={{
                            __html: searchCriteria,
                          }}
                          style={{
                            overflow: messageTargetDisplay ? 'hidden' : '',
                          }}
                        />
                        <button
                          type='button'
                          className='message-target-swith-btn'
                          onClick={() =>
                            setMessageTargetDisplay(!messageTargetDisplay)
                          }>
                          {messageTargetDisplay
                            ? '＋ 検索条件を表示'
                            : 'ー 検索条件を閉じる'}
                        </button>
                      </div>
                      <span style={{ fontSize: '12px' }}>
                        {magiContants.SEARCH_CRITERIA_DESCRIPTION}
                      </span>
                    </div>
                  )}

                {targetList && targetList.length <= senderTargetMax && (
                  <section
                    aria-label='CassetteExpand'
                    className='message-target-list'>
                    {targetList &&
                      targetList.map(applicant =>
                        applicant.readableFlag == '0' ? (
                          <div className='message-target-list__item'>
                            <div className='message-target-list__body is-accordion-content-open'>
                              <div className='message-target-list__wrap'>
                                <div className='message-target-list__inner'>
                                  権限がないため閲覧できません（ID：
                                  {applicant.jobSeekerId}）
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <Accordion
                            key={applicant.entryId}
                            className='message-target-list__item'
                            expanded={isExpaned(applicant.entryId)}
                            onClick={e => e.stopPropagation()}
                            onChange={handleExpandedChange(applicant.entryId)}>
                            <AccordionSummary
                              aria-label='Expand'
                              aria-controls={`send-list-cassette-panel${applicant.entryId}_content`}
                              id={`send-list-cassette-panel${applicant.entryId}_id`}
                              onClick={e => e.stopPropagation()}
                              className='message-target-list__head'
                              style={{ padding: '0px', width: '100%' }}>
                              <div
                                className='message-target-list__head_top'
                                style={{ width: '100%' }}>
                                {applicant.applicationType ===
                                magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION ? (
                                  <>
                                    <tr style={{ width: '100%' }}>
                                      <td>
                                        <p className='message-target-list__ttl'>
                                          匿名
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <p className='message-target-list__ttl'>
                                      {`${applicant.fullName}
                                      ${applicant.age}歳 ${applicant.progress}`}
                                      （
                                      {applicant.progressType !== '1' ? ' - ' : progressTypeList.get(applicant.decisionDivision)}
                                      ）
                                    </p>
                                  </>
                                )}
                                <span
                                  className={'message-target-display__switch'}>
                                  {isExpaned(applicant.entryId) ? (
                                    <i className='message-target-list__switch_none is-accordion-open' />
                                  ) : (
                                    <i className='message-target-list__switch_open is-accordion-open' />
                                  )}
                                </span>
                              </div>
                            </AccordionSummary>

                            <div className='message-target-list__body is-accordion-content-open'>
                              <div className='message-target-list__wrap'>
                                <div className='message-target-list__inner'>
                                  <dl className='message-target-info'>
                                    <dt className='message-target-info__head'>
                                      応募職種
                                    </dt>
                                    <dd className='message-target-info__text'>
                                      {!isEmpty(applicant.jobTitle)
                                        ? applicant.jobTitle
                                        : '-'}
                                    </dd>

                                    {applicant.entryType !==
                                      magiContants.ENTRY_TYPE_QUESTION &&
                                    applicant.entryType !==
                                      magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION ? (
                                      <>
                                        <dt className='message-target-info__head'>
                                          選考フロー
                                        </dt>
                                        <dd className='message-target-info__text'>
                                          {applicant.selectionFlow}
                                        </dd>
                                      </>
                                    ) : (
                                      <>
                                        <dt className='message-target-info__head'>
                                          選考フロー
                                        </dt>
                                        <dd className='message-target-info__text'>
                                          -
                                        </dd>
                                      </>
                                    )}

                                    <dt className='message-target-info__head'>
                                      応募日時
                                    </dt>
                                    <dd className='message-target-info__text'>
                                      {applicant.applicationDate}
                                      {'　　'}
                                      {(function() {
                                        const rlt = memberTypeNameList.get(
                                          applicant.mynaviMemberInfo
                                        )
                                        return rlt
                                      })()}
                                    </dd>

                                    {applicant.jobSeekerId &&
                                      destinationSelection ===
                                        magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (
                                        <>
                                          <dt className='message-target-info__head'>
                                            利用者番号
                                          </dt>
                                          {applicant.jobSeekerId && (
                                            <dd className='message-target-info__text'>
                                              {`${applicant.jobSeekerId}`}
                                            </dd>
                                          )}
                                        </>
                                      )}

                                    {applicant.mailAddress &&
                                      (destinationSelection ===
                                        magiContants.DESTINATION_DIVISION_EMAIL_ONLY ||
                                        applicant.mynaviMemberInfo !==
                                          magiContants.MEMBERS_TYPE_MEMBER) && (
                                        <>
                                          <dt
                                            className='message-target-info__head'
                                            style={{ width: '8em' }}>
                                            メールアドレス
                                          </dt>
                                          <dd
                                            className='message-target-info__text'
                                            style={{
                                              width: 'calc(100% - 8em)',
                                            }}>
                                            {applicant.mailAddress}
                                          </dd>
                                        </>
                                      )}
                                  </dl>

                                  <div>
                                    <i className='entry-details-message__badge ptn3'>
                                      {entryTypeList.get(
                                        applicant.applicationType
                                      )}
                                    </i>
                                  </div>
                                  {targetList.length > 1 && (
                                    <div className='message-target-delete'>
                                      <button
                                        type='button'
                                        className='message-target-delete-btn'
                                        onClick={handleDelete(
                                          applicant.entryId
                                        )}>
                                        削除する
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Accordion>
                        )
                      )}
                  </section>
                )}
              </div>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
      {/* 送信対象ブロック */}

      {/* 情報アイコン（送信先マイナビのみ） */}
      {destinationSelection === '0' && toolTip && (
        <div className='tooltip is-tooltip-show'>
          <p>
            {'マイナビ転職のマイページに送信します。'}
            <br></br>
            {'（マイナビ転職会員のみ送信可））'}
          </p>
        </div>
      )}
      {/* 情報アイコン（送信先 マイナビのみ） */}

      {/* 情報アイコン（送信先 emailのみ) */}
      {destinationSelection === '2' && toolTip && (
        <div className='tooltip is-tooltip-show'>
          <p>
            {'メールアドレスに送信します。（日程調整機能は利用できません）'}
          </p>
        </div>
      )}
      {/* 情報アイコン（送信先 emailのみ) */}

      {/* <!-- ラジオボタン --> */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='send_target_form__radio_on'
            data-name='form__radio_on'
            transform='translate(-78 -2778)'>
            <g
              transform='translate(78 2778)'
              fill='#fff'
              stroke={(isOnlyNonMember || isOnlyAnonymous || isReplyMessage) ? '#00000061' : '#3380cc'}
              stroke-width='1'>
              <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
              <circle cx='10.5' cy='10.5' r='10' fill='none' />
            </g>
            <circle
              cx='7'
              cy='7'
              r='7'
              transform='translate(81.5 2781.5)'
              fill={(isOnlyNonMember || isOnlyAnonymous || isReplyMessage) ? '#00000061' : '#3380cc'}
            />
          </g>
        </defs>
        <use xlinkHref='#send_target_form__radio_on'></use>
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        display='none'>
        <defs>
          <g
            id='send_target_form__radio_off'
            data-name='form__radio_off'
            fill='#fff'
            stroke={(isOnlyNonMember || isOnlyAnonymous || isReplyMessage) ? '#00000061' : '#1a1a1a'}
            stroke-width='1'>
            <circle cx='10.5' cy='10.5' r='10.5' stroke='none' />
            <circle cx='10.5' cy='10.5' r='10' fill='none' />
          </g>
        </defs>
        <use xlinkHref='#send_target_form__radio_off'></use>
      </svg>
      {/* <!-- /ラジオボタン --> */}

      {/* <!-- 確認ダイアログ --> */}

      <Dialog open={isEmailOnly} disableScrollLock={true}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className='dialog-section'>
            {getMessage(magiContants.MESSAGECODE_MCAZS080_065)}
          </div>
          <DialogActions className='dialog-section-btn'>
            <Button
              onClick={clickMynaviEmailOnlyCancel}
              className='daialogCancelBtn'>
              キャンセル
            </Button>
            <Button
              onClick={handleMynaviEmailOnly}
              className='daialogConfirmBtn'>
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog open={isMynaviOnly} disableScrollLock={true}>
        <div className='dialog-content' style={{ width: '100%' }}>
          <div className='dialog-section'>
            {getMessage(magiContants.MESSAGECODE_MCAZS080_063)}
          </div>
          <DialogActions className='dialog-section-btn'>
            <Button
              onClick={clickMynaviOnlyCancel}
              className='daialogCancelBtn'>
              キャンセル
            </Button>
            <Button onClick={handleMynaviOnly} className='daialogConfirmBtn'>
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default React.memo(SendTarget)
