import { magiContants as contants } from 'utils/contants'

export const routeList = {
  home: '/',
  login: '/login',
  // #91157 就職転職分離 start
  loginInduction: '/loginInduction',
  // #91157 就職転職分離 end
  readying: '/readying',
  advancedSearch: '/advancedSearch',
  applicantDetailForEmplyment: '/applicantDetailForEmplyment',
  newLoginId: '/newLoginId',
  errorReport: '/errorReport',
  temPassword: '/MCACS020',
  alert: '/alert',
  checkStatute: '/check_statute',
  commonSettingsMenu: '/commonSettingsMenu',
  password: '/commonSettingsMenu/MCACS020',
  account: '/commonSettingsMenu/MCADS010',
  recruitGroup: '/commonSettingsMenu/MCAES010',
  notAdopted: '/notAdopted',
  select: '/select',


  forcedStopSubmissionRequest:'/forcedStopSubmissionRequest',//[強制停止]
  ChangeTermRequest:'/changeTermRequest', //[提出締切の変更]

  selectionFlow: '/commonSettingsMenu/MCAJS010',
  recruitFlag: '/commonSettingsMenu/MCAHS010',
  mediaFlag: '/commonSettingsMenu/MCAIS010',
  tag: '/commonSettingsMenu/MCAKS010',
  csvTemplate: '/commonSettingsMenu/MCALS010', // CSVテンプレート一覧
  messageTemplate: '/commonSettingsMenu/messageTemplate',
  messageSign: '/commonSettingsMenu/MCANS010',
  messageSend: '/messageSend',
  mcbMessageSend: '/mcbMessageSend', // MCAZS220_My CareerBoxメッセージ入力
  // application: '/application',
  // notification: '/notification',
  entryDetail: '/entryDetail',
  applicantDetailForJobChange: '/applicantDetailForJobChange',
  dummy: '/dummy',
  interviewLocation: '/commonSettingsMenu/MCAOS010',
  searchCriteria: '/commonSettingsMenu/searchCriteria',
  notAdoptedNotification: '/commonSettingsMenu/MCAGS010',
  alertPreferenceSettings: '/commonSettingsMenu/MCAFS010',
  messageInBox: '/messageTab/MCAZS010',
  messageOutBox: '/messageTab/MCAZS020',
  messageReservation: '/messageTab/MCAZS030',
  messageDraft: '/messageTab/MCAZS040',
  messageDustBox: '/messageTab/MCAZS050',
  messageDetail: '/messageTab/MCAZS070',
  errorReportComplete: '/errorReportComplete',
  error: '/500',
  notFound: '/404',
  actionPreList: '/commonSettingsMenu/actionPreList', //MCARS050
  entryManageTabactionPreList: '/entryManageTab/actionPreList',
  attachmentList: '/commonSettingsMenu/attachmentList',
  nameList: '/commonSettingsMenu/MCAQS020',
  nameCheckInput: '/commonSettingsMenu/MCAQS010',
  csvLaoding: '/MCAXS121',
  csvDownload: '/commonSettingsMenu/MCAXS130',
  entryUploadHistory: '/entryManageTab/MCAPS020', //MCAPS020 応募者情報アップロード履歴一覧
  entryUploadErrorInfoComfirm: '/entryManageTab/MCAPS030', //MCAPS030 応募者情報アップロード エラー内容確認
  // 次期開発12月 #73504 start
  entryUploadAutoMergeComfirm: '/entryManageTab/MCAPS040', //MCAPS040 応募者情報アップロード 自動名寄せ確認
  // 次期開発12月 #73504 end
  applicationUpload: '/commonSettingsMenu/MCAPS010',
  applicantManagement: '/entryManageTab/MCAXS010',
  newsLocation: '/MCAVS020/:newsId',
  recruitmentStatusReportTab: '/recruitmentStatusReportTab/MCAVS010',
  scheduledList: '/interviewAdjustTab/MCBAS020',
  jobSeekerNameInput: '/JobSeekerNameInput', // MCAXS191_応募者氏名入力
  jobSeekerLogIn: '/JobSeekerLogIn', // MCAXS201_応募者情報登録
  entryInsEdit: '/applicantManagement/MCAXS220',
  jobSeekerInfoEdit: '/commonSettingsMenu/MCAXS230',
  jobSeekerInfoInsertConfirm: '/commonSettingsMenu/MCAXS211',
  jobSeekerInfoEditConfirm: '/commonSettingsMenu/MCAXS650',
  entryEditConfim: '/MCAXS640',
  entryEdit: '/MCAXS240',
  entryEditConfimIns: '/MCAXS630',
  pdfGenerating: '/pdf/MCAXS151',
  pdfDownload: '/pdf/MCAXS141',
  PDFoutput:'/pdf/MCBHS190',// [PDF出力]
  duplicateIdentification: '/MCAQS020',
  errreport: '/errreport',
  redirect: '/redirect/:target',
  redirect2: '/redirect/:target/:subTarget',
  duplicateLogin: '/duplicateLogin',
  interruption: '/interruption',
  entryList: '/entryManageTab/entryList',
  applicantList: '/entryManageTab/applicantList',
  myCareerBoxDataLink: '/myCareerBoxDataLink/MCBCS010', // MCBCS010_My CareerBoxデータ連携
  myCareerBoxDataLinkOK: '/myCareerBoxDataLink/MCBCS020', //MCBCS020_My CareerBoxデータ連携完了  
  // [phase2] start
  myCareerBoxCsvLaoding: '/MCBCS030',//MCBCS030_My CareerBox連携対象一覧CSV作成中
  myCareerBoxcsvDownload: '/commonSettingsMenu/MCBCS040',//MCBCS040_My CareerBox連携対象一覧CSVダウンロード
  // [phase2] end
  envCharManual: '/envCharManual',
  accessError: '/accessError',
  networkError: '/networkError',
  // MCBリプレース MCBJS020 START
  submissionReqTemplate: '/commonSettingsMenu/submissionReqTemplate',
  // MCBリプレース MCBJS020 END
  // MCBリプレース MCBHS040 START
  submissionRequestManagement: '/submissionRequestManagementTab/MCBHS040', //提出リクエスト管理
  // MCBリプレース MCBHS040 START
  // MCBリプレース MCBIS010 START
  searchCriteriaMcb: '/commonSettingsMenu/searchCriteriaMcb', // My検索管理（My CareerBox）一覧
  // MCBリプレース MCBIS010 End
  // MCBリプレース MCBHS010 START
  submissionRequestSend: '/submissionRequestSend',
  // MCBリプレース MCBHS010 END  
  // MCBリプレース MCBLS030 MCBLS020 START
  creatingMcbPdf: '/MCBLS030',
  downloadMcbPdf: '/MCBLS020',
  // MCBリプレース MCBLS030 MCBLS020 End
  // MCBリプレース MCBLS010 START
  pdfDownloadListMcb: '/MCBLS010',//My CareerBoxPDFダウンロード一覧画面へ
  // MCBリプレース MCBLS010 End
  // MCB ロット３　MCAZS170~MCAZS210 start
   mcbMessageOutBox: '/messageTab/MCAZS170',
   mcbMessageReservation: '/messageTab/MCAZS180',
   mcbMessageDraft: '/messageTab/MCAZS190',
   mcbMessageDustBox: '/messageTab/MCAZS200',
   mcbMessageDetail: '/messageTab/MCAZS210',// メッセージ詳細(My CareerBox)モックアップ
   submissionRequestMessageDetails:'/submissionRequestManagementTab/MCBHS050', //提出依頼メッセージ詳細
  // MCB ロット３　MCAZS170~MCAZS210 end
  mcazm070FileView: '/MCAZM070/FileView',// メッセージ詳細添付ファイル（ファイル表示）
  mcaxm090FileView: '/MCAXM090/FileView',// 添付ファイル一覧（ファイル表示）
   mcavm020FileView: '/MCAVM020/FileView',// お知らせ添付ファイル（ファイル表示）
}

export const routePermissionList: { [key: string]: string[] } = {
  [routeList.home]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.login]: [contants.ACCOUNT_TYPE_SUPERVISING],
  // #91157 就職転職分離 start
  [routeList.loginInduction]: [contants.ACCOUNT_TYPE_SUPERVISING],
  // #91157 就職転職分離 end
  [routeList.commonSettingsMenu]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.password]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.checkStatute]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.account]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
  ],
  [routeList.PDFoutput]:[ 
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,],
  [routeList.forcedStopSubmissionRequest]:[
      contants.ACCOUNT_TYPE_SUPERVISING,
      contants.ACCOUNT_TYPE_ADMINISTRATOR,
      contants.ACCOUNT_TYPE_SEMIMANAGER,
      contants.ACCOUNT_TYPE_GENERALUSER,
      contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.ChangeTermRequest]:[
      contants.ACCOUNT_TYPE_SUPERVISING,
      contants.ACCOUNT_TYPE_ADMINISTRATOR,
      contants.ACCOUNT_TYPE_SEMIMANAGER,
      contants.ACCOUNT_TYPE_GENERALUSER,
      contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.recruitGroup]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
  ],
  [routeList.alert]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.notAdopted]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.selectionFlow]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
  ],
  [routeList.select]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.recruitFlag]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.mediaFlag]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.tag]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.csvTemplate]: [
    //MCALS010
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.messageTemplate]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.messageSign]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.messageSend]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.mcbMessageSend]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.interviewLocation]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.searchCriteria]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.attachmentList]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.errorReport]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.readying]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.advancedSearch]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.entryDetail]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.applicantDetailForJobChange]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.dummy]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.applicantList]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.entryList]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.applicantDetailForEmplyment]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.newLoginId]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.notAdoptedNotification]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
  ],
  [routeList.errorReportComplete]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // [routeList.accountEdit]: [ contants.ACCOUNT_TYPE_SUPERVISING],
  // [routeList.company]: [ contants.ACCOUNT_TYPE_SUPERVISING],
  // [routeList.application]: [ contants.ACCOUNT_TYPE_SUPERVISING],
  // [routeList.notification]: [ contants.ACCOUNT_TYPE_SUPERVISING],
  // [routeList.extraaccount]: [ contants.ACCOUNT_TYPE_SUPERVISING],
  // [routeList.mediaList]: [ contants.ACCOUNT_TYPE_SUPERVISING],
  // [routeList.mediaEdit]: [ contants.ACCOUNT_TYPE_SUPERVISING],
  // [routeList.signUpdate]:[ contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.alertPreferenceSettings]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
  ],
  [routeList.messageInBox]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.messageOutBox]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.messageReservation]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],

  [routeList.messageDraft]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.messageDustBox]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCB ロット３　MCAZS170~MCAZS210 start
  [routeList.mcbMessageOutBox]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.mcbMessageReservation]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.mcbMessageDraft]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.mcbMessageDustBox]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.mcbMessageDetail]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCB ロット３　MCAZS170~MCAZS210 end
  // MCB ロット３　MCBHS050 start
    [routeList.submissionRequestMessageDetails]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCB ロット３　MCBHS050 end
  // MCB ロット３　TODO end
  [routeList.messageDetail]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.actionPreList]: [
    //MCARS050
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
  ],
  [routeList.entryManageTabactionPreList]: [
    //MCARS050
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
  ],
  [routeList.applicantManagement]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.newsLocation]: [
    //MCAVS020
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.recruitmentStatusReportTab]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.scheduledList]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.nameList]: [
    //MCAQS020_名寄せ結果一覧
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
  ],
  [routeList.nameCheckInput]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
  ],

  // MCAXS121
  [routeList.csvLaoding]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCAXS130
  [routeList.csvDownload]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCAPS020
  [routeList.entryUploadHistory]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCAPS030
  [routeList.entryUploadErrorInfoComfirm]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // 次期開発12月 #73504 start
  // MCAPS040
  [routeList.entryUploadAutoMergeComfirm]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
  ],
  // 次期開発12月 #73504 end
  // MCAPS010
  [routeList.applicationUpload]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
  ],
  // MCAXS191_応募者氏名入力
  [routeList.jobSeekerNameInput]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCAXS201_応募者情報登録
  [routeList.jobSeekerLogIn]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  //MCAXS220
  [routeList.entryInsEdit]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.jobSeekerInfoEdit]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.jobSeekerInfoInsertConfirm]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.jobSeekerInfoEditConfirm]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.entryEditConfimIns]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.entryEditConfim]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.entryEdit]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.pdfGenerating]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.pdfDownload]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.duplicateIdentification]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
  ],
  [routeList.redirect]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.redirect2]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.duplicateLogin]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.interruption]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.myCareerBoxDataLink]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.myCareerBoxDataLinkOK]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],

  // [phase2] start
  // MCBCS030
  [routeList.myCareerBoxCsvLaoding]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBCS040
  [routeList.myCareerBoxcsvDownload]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // [phase2] end

  [routeList.envCharManual]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  [routeList.accessError]: [contants.ACCOUNT_TYPE_SUPERVISING],
  [routeList.networkError]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBリプレース MCBJS020 START
  [routeList.submissionReqTemplate]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBリプレース MCBJS020 END
  // MCBリプレース MCBHS040 START
  [routeList.submissionRequestManagement]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBリプレース MCBHS040 END
  // MCBリプレース MCBIS010 START
  [routeList.searchCriteriaMcb]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBリプレース MCBIS0I0 END
  // MCBリプレース MCBLS030 START
  [routeList.creatingMcbPdf]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
    // MCBリプレース MCBLS030 END
     // MCBリプレース MCBHS010 START
  [routeList.submissionRequestSend]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBリプレース MCBHS010 END
  // MCBリプレース MCBLS020 START
  [routeList.downloadMcbPdf]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBリプレース MCBLS020 END
  // MCBリプレース MCBLS010 START
  [routeList.pdfDownloadListMcb]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
  // MCBリプレース MCBLS010 END
  [routeList.mcazm070FileView]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],  
  [routeList.mcaxm090FileView]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],  
  [routeList.mcavm020FileView]: [
    contants.ACCOUNT_TYPE_SUPERVISING,
    contants.ACCOUNT_TYPE_ADMINISTRATOR,
    contants.ACCOUNT_TYPE_SEMIMANAGER,
    contants.ACCOUNT_TYPE_GENERALUSER,
    contants.ACCOUNT_TYPE_LIMITUSER,
  ],
}
