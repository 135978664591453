import { Chip, ClickAwayListener } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { TextFieldForMessage } from 'sp/components'
import { FastField, Field } from 'formik'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { useModal } from 'utils'
import { FormControl } from '.'
import GroupOptions from './GroupOptions'
import TextFIeldForMessageTabAllowed from './TextFIeldForMessageTabAllowed'
import RegionModal from './Modal/RegionModal'
import RegionModalWorkPlace from './Modal/RegionModalWorkPlace'
import { Select } from './Select'
import { MultiSelect } from './MultiSelect'
import { Option } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { GroupSelect } from './GroupSelect'
import { Area } from './Modal/RegionModal'
import { magiContants } from 'utils/contants'
import TextFieldError from 'componentsHsc/AdvancedSearchJobChange/Modal/TextFieldError'

export const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.38, 0),
    fontSize: '12px',
    backgroundColor: '#eceff1',
    height: '30px',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '120px',
    marginBottom: theme.spacing(1),
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  threeColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  fourColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  datePickerDiv: {
    minheight: '40px',
    minwidth: '144px !important',
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: theme.spacing(1.625, 0.625, 0, 1),
  },
  cursor: {
    '& div': {
      cursor: 'pointer!important',
    },
  },
  itemCloumn: {
    margin: '0 0 20px 10px',
    display: 'block',
  },
  textWhile: {
    padding: '16px 8px',
    width: 'initial !important',
  },

  pMargin: {
    margin: '10px 0 0 0',
  },

  textMargin: {
    display: 'flex',
    margin: '0 0 20px 10px',
  },

  multilinePadding: {
    '& div': {
      '& div': {
        padding: '8px',
        '& textarea': {
          lineHeight: 'normal',
        },
      },
    },
  },

  marginCorrect: {
    '& div': {
      marginTop: 'initial',
      marginBottom: 'initial',
    },
  },
  itemInput: {
    alignItems: 'baseline !important',
    '& div': {
      marginTop: 'initial ',
      marginBottom: '4px',
      width: '144px',
    },
  },

}))

interface Props {
  form: FormControl
  setIsToolTipShow: (value: boolean) => void
}

// 基本情報タブ
const Basic = ({ form, setIsToolTipShow }: Props) => {
  const classes = useStyles()
  // phase2 Start
  // Modal
  const useModalResidence = (initialValue = false) => {
    const [showResidence, setShowResidence] = useState(initialValue)
    const toggleResidence = (newValue: boolean) => () =>
      setShowResidence(newValue)
    return [showResidence, toggleResidence] as const
  }

  const useModalWorkPlace = (initialValue = false) => {
    const [showWorkPlace, setShowWorkPlace] = useState(initialValue)
    const toggleWorkPlace = (newValue: boolean) => () =>
      setShowWorkPlace(newValue)
    return [showWorkPlace, toggleWorkPlace] as const
  }
  const [showResidence, toggleResidence] = useModalResidence()
  const [showWorkPlace, toggleWorkPlace] = useModalWorkPlace()

  const handleClickResidence = () => {
    setSelectedArea('hokkaidotohoku')
    toggleResidence(true)()
  }

  const handleClickWorkPlace = () => {
    setSelectedArea('hokkaidotohoku')
    toggleWorkPlace(true)()
  }
  // phase2 End
  const residenceObj = form.values.residenceObj ? form.values.residenceObj : []

  const preferredWorkplaceObj = form.values.preferredWorkplaceObj
    ? form.values.preferredWorkplaceObj
    : []

  const handleDelete = (option: Option, type: string) => () => {
    if (type === 'residenceObj') {
      form.setFieldValue(
        'residenceObj',
        residenceObj ? residenceObj.filter(i => i !== option) : null
      )
    } else if (type === 'preferredWorkplaceObj') {
      form.setFieldValue(
        'preferredWorkplaceObj',
        preferredWorkplaceObj
          ? preferredWorkplaceObj.filter(i => i !== option)
          : null
      )
    }
  }

  // 初期表示データ設定
  const {
    sexList,
    spouseList,
    finalEducationList,
    educationBackgroundList,
    languageSkillsList,
    eligibilityList,
    experienceCountList,
    employmentSituationList,
    jobCareerList,
    experiencedJobList,
  } = useSelector((state: RootState) => state.advancedSearchJobChange.basicInfo)

  const educationalBackground = educationBackgroundList.map(i => {
    return { label: i, value: i }
  })

  const languageSkillsSuggest = languageSkillsList.map(i => {
    return { label: i, value: i }
  })

  const eligibilitySuggest = eligibilityList.map(i => {
    return { label: i, value: i }
  })

  const jobCareer = jobCareerList.map(i => {
    return { label: i, value: i }
  })

  const experiencedJob = experiencedJobList.map(i => {
    return { label: i, value: i }
  })

  //都道府県モーダル用state
  const [selectedArea, setSelectedArea] = useState<Area>('hokkaidotohoku')

  // 転職Booster #90253 START
  const { serviceMode, marchServiceFlag } = useSelector(
    (state: RootState) => state.globalMenu
  )
  // 転職Booster #90253 END

  const [toolTip, setToolTip] = useState("")
  const handleToolTip = (id: string) => {
    setToolTip(id)
  }

  const handleOffToolTip = () => {
    setToolTip("")
  }

  const [toolTip0, setToolTip0] = useState("")
  const clickToolTip = (id: string) => {
    setToolTip0(id)
  }

  const clickOffToolTip = () => {
    setToolTip0("")
  }

  useEffect(() => {
    if (toolTip0.length > 0 || toolTip.length > 0) {
      setIsToolTipShow(true)
    } else {
      setIsToolTipShow(false)
    }
  }, [toolTip0, toolTip])

  return (
    <>
      <div>
        <div className='tab_content_body detail-search__tab_content is-tab-content-selected'>
          <div className={'form__ttl'}>プロフィール</div>
          <div className='form__body'>
            <div className={'form__body_ttl'}>利用者番号
              <ClickAwayListener onClickAway={handleOffToolTip}>
                <svg
                  className={'form__body_ttl_icon mr10'}
                  focusable='false'
                  viewBox='0 0 24 24'
                  aria-hidden='true'
                  onClick={() => handleToolTip("利用者番号")}>
                  <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
                </svg>
              </ClickAwayListener>
            </div>
            <div className={`form__body_item ${classes.multilinePadding} ${classes.marginCorrect}`}>
              <FastField
                name='applicantId'
                multiline
                style={''}
                component={TextFieldForMessage}
                placeholder='利用者番号を⼊⼒ ※複数登録時は改⾏区切り'
                multiLineTextArea
              />
            </div>
            <div>
              <div className={'form__body_ttl'}>氏名／氏名カナ
                <ClickAwayListener onClickAway={clickOffToolTip}>
                  <svg
                    className={'form__body_ttl_icon mr10'}
                    focusable='false'
                    viewBox='0 0 24 24'
                    aria-hidden='true'
                    onClick={() => clickToolTip("氏名／氏名カナ")}>
                    <path d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'></path>
                  </svg>
                </ClickAwayListener>
              </div>
              <div className={`form__body_item ${classes.multilinePadding} ${classes.marginCorrect}`}>
                <FastField
                  name='name'
                  multiline
                  component={TextFIeldForMessageTabAllowed}
                  placeholder='⽒名／⽒名カナを⼊⼒ ※複数登録時は改⾏区切り'
                  multiLineTextArea
                />
              </div>
            </div>
            <div>
              <div className={'form__body_ttl '}>年齢</div>
              <div className={`form__body_item_column ${classes.itemInput}`}>
                <Field
                  name='ageFrom'
                  component={TextFieldForMessage}
                  placeholder='半角数字'
                  className={'form__input_140w'}
                />
                <div className={classes.textWhile}>～</div>
                <Field
                  name='ageTo'
                  component={TextFieldForMessage}
                  placeholder='半角数字'
                  className={'form__input_140w'}
                />
              </div>
            </div>
            <div>
              <div className={'form__body_ttl'}>性別</div>
              <Field
                name='sexObj'
                row
                component={GroupOptions}
                optionList={sexList}
                groupClassName={'form__body_item'}
              />
            </div>
            <div>
              <div className={'form__body_ttl'}>メールアドレス</div>
              <div className={'form__body_item'}>
                <Field name='mailAddress'
                  placeholder="メールアドレスを入力"
                  component={TextFieldForMessage}
                  className={'form__input_100pw'}
                />
              </div>
            </div>
            <div>
              <div className='form__body_ttl'>配偶者</div>
              <Field
                name='spouseObj'
                row
                component={GroupOptions}
                optionList={spouseList}
                groupClassName={'form__body_item'}
              />
            </div>
            <div className={'form__body_ttl'}>居住地（都道府県）</div>
            <div className={classes.itemCloumn}>
              {/* phase2 Start */}
              <button
                type='button'
                onClick={handleClickResidence}
                className={'btn__blue_169 mb13'}>
                指定する
              </button>
              {/* phase2 End */}
              {residenceObj.length > 0 && (
                <div className={'form__selected-wrap'}>
                  <span>
                    {residenceObj.map(i => (
                      <Chip
                        key={i.value}
                        className={classes.chip}
                        label={i.label}
                        onDelete={handleDelete(i, 'residenceObj')}
                        deleteIcon={<CancelIcon />}
                      />
                    ))}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={'form__ttl'}>学歴</div>
        <div className={'form__body'}>
          <div className={'form__body_ttl'}>最終学歴</div>
          <Field
            name='finalEducationUniversityObj'
            row
            component={GroupOptions}
            optionList={finalEducationList}
            groupClassName={'form__body_item'}
          />
          {/* 転職Booster #90253 START */}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div>
              <div className={'form__body_ttl'}>学歴 (キーワード)</div>
              <div className={classes.itemCloumn}>
                <Field
                  name='educationalBackground'
                  isMulti
                  component={GroupSelect}
                  optionList={educationalBackground}
                  placeholder='キーワードを入力する'
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {serviceMode === magiContants.STRING_1 &&
        marchServiceFlag === magiContants.STRING_1 ? (
        ''
      ) : (
        <div>
          <div className={'form__ttl'}>資格・スキル</div>
          <div className={'form__body'}>
            <div>
              <div className={'form__body_ttl'}>語学スキル</div>
              <div className={classes.itemCloumn}>
                <Field
                  name='languageSkills'
                  component={Select}
                  className={classes.cursor}
                  defaultValue={form.values.languageSkills}
                  optionList={languageSkillsSuggest}
                  isMulti
                  placeholder='語学スキルを入力'
                />
              </div>
            </div>
            <div>
              <div className={'form__body_ttl'}>保有資格</div>
              <div className={classes.itemCloumn}>
                <Field
                  name='eligibility'
                  isMulti
                  component={Select}
                  className={classes.cursor}
                  defaultValue={form.values.eligibility}
                  optionList={eligibilitySuggest}
                  placeholder='保有資格を入力'
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 転職Booster #90253 END */}
      <div>
        <div className={'form__ttl'}>職務経歴</div>
        <div className={'form__body'}>
          <div className={'form__body_ttl'}>経験社数</div>
          <Field
            name='experienceCountObj'
            row
            component={GroupOptions}
            optionList={experienceCountList}
            groupClassName={'form__body_item'}
          />
          <div>
            <div className={'form__body_ttl'}>現在の就業状況</div>
            <Field
              name='employmentSituationObj'
              row
              component={GroupOptions}
              optionList={employmentSituationList}
              groupClassName={'form__body_item'}
            />
          </div>
          <div className='form__body_ttl'>希望勤務地（都道府県）</div>
          <div className={classes.itemCloumn}>
            {/* phase2 Start */}
            <button
              type='button'
              className={'btn__blue_169 mb13'}
              onClick={handleClickWorkPlace}>
              指定する
            </button>
            {/* phase2 End */}
            <div className='form__selected-wrap'>
              {preferredWorkplaceObj.length > 0 && (
                <span>
                  {preferredWorkplaceObj.map(i => (
                    <Chip
                      key={i.value}
                      className={classes.chip}
                      label={i.label}
                      onDelete={handleDelete(i, 'preferredWorkplaceObj')}
                      deleteIcon={<CancelIcon />}
                    />
                  ))}
                </span>
              )}
            </div>
          </div>
          {/* 転職Booster #90253 START */}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div>
              <div className={'form__body_ttl'}>職務経歴</div>
              <div className={classes.itemCloumn}>
                <Field
                  name='jobCareer'
                  isMulti
                  component={Select}
                  className={classes.cursor}
                  defaultValue={form.values.jobCareer}
                  optionList={jobCareer}
                  placeholder='職務履歴を入力する'
                />
              </div>
            </div>
          )}
          {serviceMode === magiContants.STRING_1 &&
            marchServiceFlag === magiContants.STRING_1 ? (
            ''
          ) : (
            <div>
              <div className={'form__body_ttl'}>経験職種</div>
              <div className={classes.itemCloumn}>
                <Field
                  name='experiencedJob'
                  isMulti
                  component={Select}
                  className={classes.cursor}
                  defaultValue={form.values.experiencedJob}
                  optionList={experiencedJob}
                  placeholder='経験職種を入力する'
                />
              </div>
            </div>
          )}
        </div>
        {/* 転職Booster #90253 END */}
      </div>
      <div>
        <div className={'form__ttl'}>フリーワード</div>
        <div className={'form__body'}>
          <div className={'form__body_ttl'}>履歴書全体 (キーワード)</div>
          <div className={classes.itemCloumn}>
            <FastField
              name='wholeResume'
              component={MultiSelect}
              placeholder='キーワードを入力する'
              className={classes.cursor}
            />
            <p className={classes.pMargin}>※Enterキー押下で入力内容をセットします。</p>
          </div>
        </div>
      </div>
      {/* phase2 Start */}
      {showResidence && (
      <RegionModal
        open={showResidence}
        onClose={toggleResidence(false)}
        form={form}
        name={'residenceObj'}
        selectedAreaState={[selectedArea, setSelectedArea]}
      />
      )}
      {showWorkPlace && (
      <RegionModalWorkPlace
        open={showWorkPlace}
        onClose={toggleWorkPlace(false)}
        form={form}
        name={'preferredWorkplaceObj'}
        selectedAreaState={[selectedArea, setSelectedArea]}
      />
      )}
      {/* phase2 End */}
      {/* 利用者番号 */}
      {toolTip === "利用者番号" && (
        <div id='tooltip-1' className={'tooltip is-tooltip-show'}>
          <p>
            複数指定する場合は改行で入力してください。一度に設定できるのは10,000件です。
          </p>
        </div>
      )}
      {/* 利用者番号 */}
      {/* 氏名／氏名カナ */}
      {toolTip0 === "氏名／氏名カナ" && (
        <div id='tooltip-2' className={'tooltip is-tooltip-show'}>
          <p>
            複数指定する場合は改行で入力してください。一度に設定できるのは100件です。
          </p>
        </div>
      )}
      {/* 氏名／氏名カナ */}
    </>
  )
}

export default Basic
